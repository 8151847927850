<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else>
      <p v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</p>
      <div v-else>
        <div class="row align-items-center col-md-10 mx-auto">
          <h5 class="col-4 text-black-50 m-0">{{ FA_LAN.ALL_REVIEW_COUNT }}
            <span class="text-primary">{{ allCount }}</span>
          </h5>
          <h5 class="col-4 text-black-50 m-0">{{ FA_LAN.REVIEW_LOTTERY_COUNT }}
            <span class="text-primary">{{ lotteryCount }}</span>
          </h5>
          <button class="btn btn-success col-4" data-bs-toggle="offcanvas"
                  data-bs-target="#lottery" aria-controls="offcanvasBottom" @click="start">
            {{ FA_LAN.SUBMIT_LOTTERY }}
          </button>
          <ReviewLottery :start="startLottery"/>
        </div>
        <hr>
        <div class="row mb-4" id="reviews">
          <div class="col-3" v-for="review in reviews" :id="review.id" :key="review.id">
            <div class=" card shadow-me mt-3 ms-2">
              <div class="row text-black-50 ps-2 pe-2 pt-2 dir-ltr">
                <p class="col-7">{{ tojalali(review.date) }} </p>
                <p class="col-5">{{ review.phone }}</p>
              </div>
              <div class="row ps-2 pe-2">
                <p class="col-9 small" :class="{'text-black-50':review.text===null}">{{ getText(review.text) }}</p>
                <p class="golden-text col-3">{{ review.rate }} <i class="bi bi-star-fill"></i></p>
              </div>
              <div class="small card-header">
                {{ getCategoryName(review.main_category) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {computed, onMounted, ref} from "vue";
import {gregorian_to_jalali} from "@/js/date/convert";
import {useStore} from "vuex";
import ReviewLottery from "@/components/ReviewLottery";

export default {
  name: "Lottery",
  components: {ReviewLottery},
  setup() {
    const FA_LAN = persian;
    const loading = ref(true)
    const empty = ref(true);
    const store = useStore();
    const reviews = computed(() => store.getters["reviewLottery/get"]);
    const allCount = computed(() => store.getters["reviewLottery/getAllCount"]);
    const lotteryCount = computed(() => store.getters["reviewLottery/getLotteryCount"]);
    const lotteryLoader = ref(false);
    const startLottery = ref(false);

    onMounted(async () => {
      await getReviewList();
    })

    async function getReviewList() {
      await store.dispatch('reviewLottery/fetch');

      checKEmptyObject();

      loading.value = false;
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = reviews.value.length === 0;
    }

    function getText(text) {
      let outputText;
      if (text === null) {
        outputText = FA_LAN.NOT_TEXT;

      } else if (text.length > 25) {
        outputText = text.substr(0, 25) + "..."

      } else {
        outputText = text.substr(0, 25);

      }

      return outputText;
    }

    function getCategoryName(code) {
      let category;
      category = store.getters["mainCategory/filter"](code);

      if (!category) {
        category = store.getters["packages/filter"](code);
      }

      return category ? category.title : '';
    }

    function start() {
      startLottery.value = true;
    }

    return {
      FA_LAN,
      loading,
      empty,
      reviews,
      allCount,
      lotteryCount,
      lotteryLoader,
      startLottery,
      tojalali,
      getText,
      getCategoryName,
      start,
    }
  }
}
</script>

<style scoped>
.golden-text {
  color: #FFA500;
}

.card-header {
  border-bottom: none;
}

.card{
  min-width: 50px !important;
}

.dir-ltr {
  direction: ltr !important;
}
</style>