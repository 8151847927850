<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="row justify-content-center mb-4" ref='scrollComponent'>
      <div class="card shadow-me table-responsive col-md-8 p-4 pb-0 mt-2">
        <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
        <table v-else class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.PHONE }}</th>
            <th scope="col">{{ FA_LAN.DATE }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.phone }}</td>
            <td>{{ tojalali(user.created_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3 mb-3" v-if="moreLoader">
        <div class="spinner-border" role="status" v-if="moreLoader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";
import {sweetAlert} from "../js/alert/sweetAlert";
import {getPost} from "../js/api/restAPI";
import persian from "../fa-IR/fa-ir";
import {gregorian_to_jalali} from "../js/date/convert";

export default {
  name: "User",
  setup() {
    const FA_LAN = persian;
    const users = ref([]);
    const loading = ref(true);
    const moreLoader = ref(false);
    const index = ref(0);
    const scrollComponent = ref(null);
    const empty = ref(true);

    onMounted(async () => {
      await getUsers();
      await loadMore();//for large screen

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function getUsers() {
      await getPost(`admin/getUsersList?index=${index.value}`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              users.value.push(...response.data.data.users);
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      loading.value = false;
      moreLoader.value = false;
      checKEmptyObject();
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - users.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getUsers();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = users.value.length === 0;
    }

    function checkPreviousReceive() {
      return users.value.length === index.value + 10;
    }


    return {users, loading, moreLoader, FA_LAN, scrollComponent, empty, tojalali}
  }
}
</script>

<style scoped>

</style>