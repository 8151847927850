<template>
  <nav class="navbar navbar-expand-md navbar-light sticky-top bg-white">
    <div class="navbar-nav nav-expand-menu desktop">
      <MenuItems/>
    </div>
    <div class="mobile">
      <button class="btn btn-link text-muted text-decoration-none" type="button" data-bs-toggle="offcanvas"
              data-bs-target="#menuMobile"
              aria-controls="menuMobile">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="menuMobile" aria-labelledby="menuMobileLabel">
        <div class="offcanvas-body">
          <ul class="mobile-menu p-0">
            <MenuItems/>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import MenuItems from "./MenuItems";

export default {
  name: "Menu",
  components: {MenuItems}
}
</script>

<style>
.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .mobile {
    display: block !important;
  }

  .desktop {
    display: none !important;
  }
}

.mobile-menu li:hover{
  background: none;
  color: #39C0ED  !important;
  cursor: pointer;
}

.mobile-menu li {
  list-style-type: none;
  line-height: 1.5rem;
  text-align: start;
  }
</style>