<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else ref='scrollComponent'>
      <div class="d-flex justify-content-center mt-lg-5 row">
        <form class="col-md-8">
          <div class="row">
            <div class="mb-3 col-6">
              <label class="form-label">{{ FA_LAN.PHONE }}</label>
              <input class="form-control" type="number" v-model.lazy="phone"/>
              <div class="form-text text-danger">{{ textError }}</div>
            </div>
            <div class="col-6 d-flex justify-content-sm-start align-items-center ">
              <button type="submit" class="btn btn-dark btn-sm pe-3 ps-3" @click.prevent="search"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.RECEIVING_DATA }}
              </button>
            </div>
          </div>
        </form>
        <div class="col-md-4 d-flex justify-content-end align-items-end">
          <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'newOffForUser',params:{tempID:tempID}}">
            {{ FA_LAN.SUBMIT_USER_OFF }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="table-responsive">
        <table class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.OFF_TITLE }}</th>
            <th scope="col">{{ FA_LAN.OFF_PLAN_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.CREATED_AT }}</th>
            <th scope="col">{{ FA_LAN.VALID_DATE }}</th>
            <th scope="col">{{ FA_LAN.PHONE }}</th>
            <th scope="col">{{ FA_LAN.OFF_USER_COUNT }}</th>
            <th scope="col">{{ FA_LAN.USED }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
          <tbody v-else>
          <tr v-for="off in offs" :key="off.id">
            <td>{{ off.title }}</td>
            <td>{{ off.amount }}{{ offType(off.percentage) }}</td>
            <td>{{ tojalali(off.created_at) }}</td>
            <td>{{ tojalali(off.valid_date) }}</td>
            <td>{{ off.phone }}</td>
            <td>{{ off.limit_count }}</td>
            <td>{{ off.used_count }}</td>
            <td>
              <button class="btn btn-sm btn-outline-danger" @click="deleteOff(off.id)" :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.DELETE }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3 mb-3" v-if="loadMoreButton">
        <!--        <button @click="loadMore" class="btn btn-outline-secondary btn-sm pe-3 ps-3" :disabled="moreLoader">-->
        <div class="spinner-border" role="status" v-if="moreLoader"></div>
        <!--          {{ FA_LAN.LOAD_MORE }}-->
        <!--        </button>-->
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, onUnmounted, ref} from "vue";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {gregorian_to_jalali} from "../../js/date/convert";
import persian from "../../fa-IR/fa-ir";
import {useRoute} from "vue-router";

export default {
  name: "Search",
  setup() {
    const phone = ref('');
    const textError = ref('');
    const formLoader = ref(false)
    const loading = ref(true)
    const moreLoader = ref(false)
    const empty = ref(true);
    const loadMoreButton = ref(true);
    const offs = ref([]);
    const index = ref(0);
    const FA_LAN = persian;
    const scrollComponent = ref(null);
    const successDelete = ref(false);
    const route = useRoute();
    const tempID = route.params.tempID

    async function search() {
      if (phone.value === '') {
        textError.value = FA_LAN.ERRORS.PHONE_NOT_ENTER;
        return;
      }

      textError.value = '';
      formLoader.value = true;

      await getNewList();
    }

    async function getNewList() {
      offs.value = [];
      await getOffList();
    }

    async function getOffList() {

      await getPost(`admin/off/getOneTemplateReport?index=${index.value}`, {
        phone: phone.value,
        temp_id: tempID
      })
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              offs.value.push(...response.data.data.offs); //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      loading.value = false;
      formLoader.value = false;
      moreLoader.value = false;
    }

    onMounted(async () => {
      await getOffList();
      await loadMore();//for large screen

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - offs.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getOffList();
      }
    }

    async function deleteOff(off_id) {
      successDelete.value = false;
      formLoader.value = true;

      await getPost(`admin/off/delete`, {off_id: off_id})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);

              successDelete.value = true;
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      if (successDelete.value) {
        loading.value = true;
        await getNewList();
        loading.value = false;
      }

      formLoader.value = false;
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = offs.value.length === 0;
    }

    function offType(isPercentage) {
      return isPercentage ? FA_LAN.PERCENTAGE : FA_LAN.TOMAN;
    }

    function checkPreviousReceive() {
      return offs.value.length === index.value + 10;
    }

    return {
      phone,
      textError,
      formLoader,
      empty,
      offs,
      FA_LAN,
      loading,
      moreLoader,
      loadMoreButton,
      scrollComponent,
      tempID,
      loadMore,
      tojalali,
      search,
      offType,
      deleteOff,
    }
  }
}
</script>

<style scoped>

</style>