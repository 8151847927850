import {createRouter, createWebHistory} from "vue-router";
import Home from "./pages/Home";
import TicketTemplate from './pages/ticket/Template'
import Ticket from './pages/ticket/Tickets'
import Chat from './pages/ticket/Chat'
import TicketCategory from './pages/ticket/category/Category'
import TicketCategoryTemplate from './pages/ticket/category/Template'
import NewTicketCategories from './pages/ticket/category/New'

import BuTemplate from './pages/buy/Template'
import NewBuy from './pages/buy/New'
import Buy from './pages/buy/Search'

import CategoryTemplate from './pages/category/Template'
import Categories from "./pages/category/Categories";
import NewCategory from "./pages/category/New";
import CreateCategoryEducation from './pages/category/educations/New'
import CategoryVideos from './pages/category/educations/Videos'
import DeleteCategoryEducation from './pages/category/educations/Delete'
import SubCategories from "./pages/category/subCategory/SubCategories";
import NewSubCategory from "./pages/category/subCategory/New";


import PackageTemplate from './pages/package/Template'
import Packages from "./pages/package/Packages";
import NewPackage from "./pages/package/New";
import CreatePackageEducation from './pages/package/educations/New'
import PackageVideos from './pages/package/educations/Videos'
import DeletePackageEducation from './pages/package/educations/Delete'

import OffTemplate from './pages/off/Template'
import Off from './pages/off/Summary'
import OffTempReport from './pages/off/Users'
import NewOff from './pages/off/New'
import OffForUser from './pages/off/ForUser'

import ReviewsTemplate from './pages/reviews/Template'
import Reviews from './pages/reviews/Summary'
import ShowReviews from './pages/reviews/Reviews'
import AddReason from './pages/reviews/AddReason'
import ReviewLottery from './pages/reviews/Lottery'

import PlansTemplate from "./pages/plan/Template";
import PlansList from "./pages/plan/List";
import NewPlan from "./pages/plan/New";

import WalletTemplate from './pages/wallet/Template';
import WalletReport from './pages/wallet/Report';
import WallerTransactions from './pages/wallet/Transactions';
import WalletAmount from './pages/wallet/Amount';

import Sms from './pages/Sms'
import User from "./pages/User";
import Etc from "./pages/Etc";

const routes = [
    {path: '/', name: 'home', component: Home},
    {
        path: '/tickets', name: "ticketTemplate", component: TicketTemplate, children: [
            {path: '', name: 'ticket', component: Ticket},
            {path: 'chats/:id', name: 'chats', component: Chat},
            {
                path: 'categories', name: 'ticketTemplate', component: TicketCategoryTemplate, children: [
                    {path: '', name: 'ticketCategories', component: TicketCategory},
                    {path: 'new', name: 'newTicketCategories', component: NewTicketCategories},
                ]
            },

        ]
    },

    {
        path: '/buys', name: "buyTemplate", component: BuTemplate, children: [
            {path: '', name: 'buy', component: Buy},
            {path: 'new', name: 'newBuy', component: NewBuy},
        ]
    },
    {
        path: '/categories', name: "categoryTemplate", component: CategoryTemplate, children: [
            {path: '', name: 'category', component: Categories},
            {path: 'new', name: 'newCategory', component: NewCategory},
            {path: 'education/create', name: 'createEducation', component: CreateCategoryEducation},
            {path: 'education/delete/:id', name: 'deleteEducation', component: DeleteCategoryEducation},
            {path: 'videos/:mainCategory', name: 'getEducationVideo', component: CategoryVideos},
            {path: ':mainCategory/sub', name: 'getSubcategory', component: SubCategories},
            {path: ':mainCategory/sub/new', name: 'newSubcategory', component: NewSubCategory},
        ]
    },
    {
        path: '/packages', name: "packagesTemplate", component: PackageTemplate, children: [
            {path: '', name: 'package', component: Packages},
            {path: 'new', name: 'newPackage', component: NewPackage},
            {path: 'education/create', name: 'createPackageEducation', component: CreatePackageEducation},
            {path: 'education/delete/:id', name: 'deletePackageEducation', component: DeletePackageEducation},
            {path: 'videos/:package', name: 'getPackageEducationVideo', component: PackageVideos},
        ]
    },
    {
        path: '/off', name: "offTemplate", component: OffTemplate, children: [
            {path: '', name: 'off', component: Off},
            {path: 'new', name: 'newOff', component: NewOff},
            {path: 'template/:tempID', name: 'offTemplateUsers', component: OffTempReport},
            {path: 'forUser/:tempID?', name: 'newOffForUser', component: OffForUser},
        ]
    },
    {
        path: '/reviews', name: "reviewsTemplate", component: ReviewsTemplate, children: [
            {path: '', name: 'reviews', component: Reviews},
            {path: ':mainCategory', name: 'showReviews', component: ShowReviews},
            {path: 'addReason', name: 'addReason', component: AddReason},
            {path: 'lottery', name: 'reviewLottery', component: ReviewLottery},
        ]
    },
    {
        path: '/plans', name: "plansTemplate", component: PlansTemplate, children: [
            {path: '', name: 'plans', component: PlansList},
            {path: 'new', name: 'newPlans', component: NewPlan},
        ]
    },
    {
        path: '/wallet', name: "walletTemplate", component: WalletTemplate, children: [
            {path: '', name: 'walletReport', component: WalletReport},
            {path: 'transactions', name: 'walletTransactions', component: WallerTransactions},
            {path: 'wallet', name: 'walletAmounts', component: WalletAmount},
        ]
    },
    {path: '/sms', name: 'sms', component: Sms},
    {path: '/users', name: 'users', component: User},
    {path: '/Etc', name: 'etc', component: Etc},
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;

