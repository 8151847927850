<template>
  <div>
    <div class="d-flex col-md-8">
      <div class="ms-4">
        <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'createPackageEducation'}">
          {{ FA_LAN.EDUCATION_SUBMIT }}
        </router-link>
      </div>
      <div class="ms-4">
        <router-link class="btn btn-dark btn-sm pe-3 ps-3" :to="{name:'newPackage'}">
          {{ FA_LAN.PACKAGE_SUBMIT }}
        </router-link>
      </div>
    </div>
    <hr>
    <div class="d-flex justify-content-center mt-3">
      <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
      <div v-else class="card shadow-me p-3 table-responsive col-md-10">
        <table class="table table-hover table-striped">
          <thead>
          <tr>
            <th scope="col">{{ FA_LAN.PACKAGE_NAME }}</th>
            <th scope="col">{{ FA_LAN.CATEGORY_CODE }}</th>
            <th scope="col">{{ FA_LAN.CATEGORY_LOGO }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="packageItem in packageLists" :key="packageItem.id">
            <td>
              <router-link class="text-black link"
                           :to="{name:'getPackageEducationVideo',params:{package:packageItem.code}}">
                {{ packageItem.title }}
              </router-link>
              <div><small class="text-muted"> {{ packageItem.subtitle }}</small></div>
            </td>
            <td>{{ packageItem.code }}</td>
            <td><img class="text-black link" :src="packageItem.logo"/></td>
            <td>
              <button type="button" class="btn btn-sm btn-link text-body text-decoration-none"
                      data-bs-toggle="modal" :data-bs-target="'#modal'+packageItem.code">
                {{ FA_LAN.PACKAGE_MORE_DESCRIPTION }}
              </button>
              <Modal :text="packageItem.description" :id="'modal'+packageItem.code"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {computed, onMounted, ref, watch} from "vue";
import {gregorian_to_jalali} from "../../js/date/convert";
import {useStore} from "vuex";
import Modal from "../../components/Modal";

export default {
  name: "Educations",
  components: {Modal},
  setup() {
    const store = useStore()
    const packageLists = computed(() => store.getters["packages/get"]);
    const empty = ref(true);
    const FA_LAN = persian;

    onMounted(() => {
      checKEmptyObject();
    })

    watch(packageLists, () => {
      checKEmptyObject();
    })

    function checKEmptyObject() {
      empty.value = packageLists.value.length === 0;
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    return {FA_LAN, packageLists, empty, tojalali}
  }
}
</script>

<style scoped>
img {
  width: 35px;
  height: auto;
}
</style>