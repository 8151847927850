<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8 card shadow-me p-3">
      <div class="form-check form-switch mb-3">
        <label v-if="isMainCategory" class="form-check-label" for="changeCategory">
          {{ FA_LAN.MAIN_CATEGORY_PLAN }}
        </label>
        <label v-else class="form-check-label" for="changeCategory">{{ FA_LAN.PACKAGE_PLAN }}</label>
        <input type="checkbox" class="form-check-input" id="changeCategory" v-model="isMainCategory">
      </div>
      <div v-if="isMainCategory">
        <div class="mb-3">
          <div class="form-group">
            <label class="form-label">{{ FA_LAN.MAIN_CATEGORY }}</label>
            <select class="form-control" v-model.lazy="plan.main_category">
              <option v-for="mainCategory in mainCategories" :value="mainCategory.code" :key="mainCategory.id">
                {{ mainCategory.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <div class="form-group">
            <label class="form-label">{{ FA_LAN.SUB_CATEGORY }}</label>
            <select class="form-control" v-model.lazy="plan.sub_category">
              <option v-for="subCategory in subCategories" :key="subCategory.id" :value="subCategory.code">
                {{ subCategory.title }}
              </option>
            </select>
            <div class="spinner-border spinner-border-sm" role="status" v-if="subLoader"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="mb-3">
          <div class="form-group">
            <label class="form-label">{{ FA_LAN.PACKAGE_NAME }}</label>
            <select class="form-control" v-model.lazy="plan.main_category">
              <option v-for="packageItem in packages" :value="packageItem.code" :key="packageItem.id">
                {{ packageItem.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.BAZAAR_CODE }}</label>
        <input class="form-control" type="text" v-model.lazy="plan.bazaar_code"/>
      </div>
      <div v-if="isMainCategory">
        <div class="form-check form-switch mb-3">
          <label v-if="isPlan" class="form-check-label" for="changePlan">{{ FA_LAN.GROUP_PLAN }}</label>
          <label v-else class="form-check-label" for="changePlan">{{ FA_LAN.SINGLE_PLAN }}</label>
          <input type="checkbox" class="form-check-input" id="changePlan" v-model="isPlan">
        </div>
      </div>
      <div v-if="isPlan || !isMainCategory" class="mb-3">
        <label class="form-label">{{ FA_LAN.MAIN_AMOUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="plan.base_amount"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_AMOUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="plan.amount"/>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.NEW_PLAN_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {computed, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

export default {
  name: "New",
  setup() {
    const store = useStore();
    const mainCategories = computed(() => store.getters["mainCategory/get"]);
    const packages = computed(() => store.getters["packages/get"]);
    const formLoader = ref(false);
    const FA_LAN = persian;
    const subCategories = computed(() => store.getters["subCategory/get"])
    const subLoader = ref(false);
    const oldMainCategory = ref('');
    const isMainCategory = ref(true);
    const isPlan = ref(true);
    const successEdit = ref(false);
    const plan = reactive({
      main_category: '',
      sub_category: '',
      amount: '',
      base_amount: '',
      bazaar_code: '',
    })

    watch(plan, async () => {
      if (plan.main_category !== oldMainCategory.value) {//for not run in every field change
        subLoader.value = true;
        await store.dispatch("subCategory/fetch", {mainCategory: plan.main_category});
        subLoader.value = false;

        oldMainCategory.value = plan.main_category;
      }
    })

    async function submit() {
      formLoader.value = true

      let url;

      if (!isMainCategory.value) {
        plan.sub_category = null;
      }

      if (isMainCategory.value && !isPlan.value) {
        url = 'admin/plans/single/new';

      } else {
        url = 'admin/plans/new';
      }

      await getPost(url, plan)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);
              successEdit.value = true;
            }
          }).catch(function (error) {
            sweetAlert('error', error);
          })

      if (successEdit.value) {
        if (isMainCategory.value && !isPlan.value) {
          await store.dispatch('plan/fetchSingle');

        } else {
          await store.dispatch('plan/fetch');

        }

        successEdit.value = false;
      }

      formLoader.value = false;
    }

    return {
      FA_LAN,
      formLoader,
      packages,
      isPlan,
      mainCategories,
      subCategories,
      subLoader,
      plan,
      isMainCategory,
      submit
    }
  }
}
</script>

<style scoped>
.form-check .form-check-input {
  float: right !important;
  margin-right: 1.5em !important;
  margin-left: 0.5em !important;
}
</style>