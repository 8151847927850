const sweetAlert = {
    successTitle: 'آفرین',
    errorTitle: 'ای بابا',
    successText: 'عملیات با موفقیت انجام شد',
    bottomText: 'خب',
    NO_INTERNET:"لطفا دسترسی خود به اینترنت را بررسی نمایید",


    //delete admin
    DELETE_ADMIN_TITLE:"ایا از حذف کاربر (username) مطمئن هستید؟",
    YES:"بله",
    NO:"انصراف",

    //push
    CONFIRM_SEND_PUSH:"ایا از ارسال پوش مطمئن هستید؟",

    //pwa banner
    PWA_THANKS:"با تشکر، برنامه با موفقیت نصب شد",
    PWA_DENIED:"ای بابا، یعنی دوستم نداشتی؟ بی وفا؟"

}

export default sweetAlert;