import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const plan = {
    namespaced: true,
    state: {
        plans: [],
        singlePlans: []
    },
    getters: {
        get(state) {
            return state.plans;
        },
        getSingle(state) {
            return state.singlePlans;
        },
        find: (state) => (planID) => {
            return state.plans.find(p => p.id == planID)
        }
    },
    mutations: {
        set(state, data) {
            state.plans = data;
        },
        setSingles(state, data) {
            state.singlePlans = data;
        }
    }
    ,
    actions: {
        async fetch({commit}) {

            await getPost('new/getPayPlans', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        commit("set", response.data.data.plans);
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        },
        async fetchSingle({commit}) {

            await getPost('getPaySingle', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        commit("setSingles", response.data.data.plans);
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default plan