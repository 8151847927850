<template>
  <transition name="fade">
    <Menu v-if="loginStatus"/>
  </transition>
  <div class="container mt-4">
    <router-view v-slot="{ Component }" v-if="loginStatus">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
    <Login v-else/>
    <PWABanner/>
  </div>
</template>

<script>
import Menu from "./components/Menu";
import Login from "./components/Login";
import PWABanner from "@/components/PWABanner";

import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from "vuex";

export default {
  name: 'App',
  components: {PWABanner, Login, Menu},
  setup() {
    const loading = ref(false);
    const store = useStore();
    const loginStatus = computed(() => store.getters["apiKey/status"]);

    onMounted(async () => {
      await checkLoginBefore();

      if (loginStatus.value) {
        await fetch()
      }
    })

    watch(loginStatus, () => {
      fetch();
    })

    async function fetch() {
      //get main data
      await store.dispatch("plan/fetch");
      await store.dispatch("mainCategory/fetch");
      await store.dispatch("packages/fetch");
    }

    async function checkLoginBefore() {
      await store.dispatch("apiKey/checkLogin");
    }

    return {loginStatus, loading}
  }
}
</script>

<style>

@font-face {
  font-family: "iransans";
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/iransans.eot');
  src: url("./fonts/iransans.eot?#iefix") format("embedded-opentype"),
  url("./fonts/iransans.woff") format("woff"),
  url("./fonts/iransans.ttf") format("truetype");
}

body {
  text-align: right;
  direction: rtl;
  color: #333 !important;
  font-family: "iransans" !important;
  font-size: 14px !important;
  background-color: #f5f5f5 !important;
}

table {
  color: #333 !important;
}

th {
  font-weight: normal !important;
}

.fade-enter-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from {
  opacity: 0;
}

.shadow-me {
  box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
  border: 0 !important;
}

.card {
  min-width: 300px !important;
  border-radius: 0px 15px !important;
}

.link:hover {
  color: #39C0ED;
}

.text-black {
  text-decoration: none;
  color: black;
}

/* The Modal (background) */
.my-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.my-modal-content {
  text-align: center;
  margin: 15% auto; /* 15% from the top and centered */
}

.offcanvas-bottom {
  height:  auto !important; /* Height of navbar */
}
</style>
