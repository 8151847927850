<template>
  <router-view></router-view>
</template>
<script>
import {useStore} from "vuex";
import {onMounted} from "vue";

export default {
  name: "Template",
  setup() {
    const store = useStore();

    onMounted(async () => {
      await store.dispatch('reviewRejectReason/fetch');
    })
  }
}
</script>

<style scoped>

</style>