<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else ref='scrollComponent'>
      <div class="d-flex row">
        <div class="col-md-4 d-flex justify-content-start align-items-start">
          <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'newOffForUser'}">
            {{ FA_LAN.SUBMIT_USER_OFF }}
          </router-link>
          <router-link class="btn btn-dark btn-sm pe-3 ps-3 me-2" :to="{name:'newOff'}">
            {{ FA_LAN.SUBMIT_OFF }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="table-responsive bg-white p-3 card shadow-me">
        <table class="table table-striped table-hover bg-white">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.OFF_TITLE }}</th>
            <th scope="col">{{ FA_LAN.OFF_PLAN_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.CREATED_AT }}</th>
            <th scope="col">{{ FA_LAN.VALID_DATE }}</th>
            <th scope="col">{{ FA_LAN.USED }}</th>
            <th></th>
          </tr>
          </thead>
          <span v-if="publicEmpty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
          <tbody v-else>
          <tr v-for="off in publicOffs" :key="off.id">
            <td>{{ off.title }}</td>
            <td>{{ off.amount }}{{ offType(off.percentage) }}</td>
            <td>{{ tojalali(off.created_at) }}</td>
            <td>{{ tojalali(off.valid_date) }}</td>
            <td>{{ off.used_count }}</td>
            <td>
              <button class="btn btn-sm btn-outline-danger" @click="deleteOff(off.id)" :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.DELETE }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive bg-white p-3 card shadow-me mt-4">
        <table class="table table-striped table-hover ">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.OFF_TITLE }}</th>
            <th scope="col">{{ FA_LAN.OFF_PLAN_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.OFF_USER_COUNT }}</th>
            <th scope="col">{{ FA_LAN.USED }}</th>
          </tr>
          </thead>
          <span v-if="templateEmpty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
          <tbody v-else>
          <tr v-for="off in templateOffs" :key="off.id" @click="showTemplateUsers(off.id)">
            <td>{{ off.title }}</td>
            <td>{{ off.amount }}{{ offType(off.percentage) }}</td>
            <td>{{ off.limit_count }}</td>
            <td>{{ off.used_count }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import persian from "@/fa-IR/fa-ir";
import {getPost} from "@/js/api/restAPI";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {gregorian_to_jalali} from "@/js/date/convert";
import {useRouter} from "vue-router";

export default {
  name: "Summary",
  setup() {
    const loading = ref(true)
    const publicEmpty = ref(true);
    const templateEmpty = ref(true);
    const FA_LAN = persian;
    const publicOffs = ref([]);
    const templateOffs = ref([]);
    const router = useRouter();
    const successDelete = ref(false);
    const formLoader = ref(false);

    onMounted(async () => {
      await getPublicOffs();
      await getTemplateOffs();

      loading.value = false;
    })

    async function getPublicOffs() {
      await getPost(`admin/off/getPublicReport`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              publicOffs.value = response.data.data.offs; //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })
    }

    async function getTemplateOffs() {
      await getPost(`admin/off/getTemplateReport`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              templateOffs.value = response.data.data.offs; //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })
    }

    async function deleteOff(off_id) {
      successDelete.value = false;
      formLoader.value = true;

      await getPost(`admin/off/delete`, {off_id: off_id})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);

              successDelete.value = true;
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      if (successDelete.value) {
        loading.value = true;
        await getPublicOffs();
        loading.value = false;
      }

      formLoader.value = false;
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      publicEmpty.value = publicOffs.value.length === 0;
      templateEmpty.value = templateOffs.value.length === 0;
    }

    function offType(isPercentage) {
      return isPercentage ? FA_LAN.PERCENTAGE : FA_LAN.TOMAN;
    }

    function showTemplateUsers(id) {
      router.push({name: "offTemplateUsers", params: {tempID: id}})
    }

    return {
      loading,
      publicEmpty,
      templateEmpty,
      FA_LAN,
      publicOffs,
      templateOffs,
      formLoader,
      tojalali,
      offType,
      showTemplateUsers,
      deleteOff
    }
  }
}
</script>

<style scoped>

</style>