<template>
  <div>
    <div class="row col-md-8">
      <div class="col-md-4 mt-2">
        <label>{{ FA_LAN.BEGIN_DATE }}</label>
        <DatePicker v-model="date.begin"/>
      </div>
      <div class="col-md-4 mt-2">
        <label>{{ FA_LAN.END_DATE }}</label>
        <DatePicker v-model="date.end"/>
      </div>
      <div class="col-md-4 d-flex justify-content-sm-start align-items-end mt-2">
        <button type="submit" class="btn btn-dark btn-sm pe-3 ps-3" @click.prevent="filter" :disabled="formLoader">
          <!--          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">-->
          <!--          </div>-->
          {{ FA_LAN.FILTER }}
        </button>
        <button type="submit" class="btn btn-outline-secondary btn-sm pe-3 ps-3 me-4" @click.prevent="clearFilter"
                :disabled="formLoader">
          <!--          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">-->
          <!--          </div>-->
          {{ FA_LAN.DELETE }}
        </button>
      </div>
    </div>
    <hr>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="row d-flex align-items-start">
      <div class="row col-md-4">
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p class="m-0">
            <router-link class="btn btn-link btn-sm text-body text-decoration-none" :to="{name:'users'}">
              {{ FA_LAN.ALL_USER_COUNT }}: {{ summeryData.allUser }}
            </router-link>
          </p>
        </div>
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p class="m-0">
            <router-link class="btn btn-link btn-sm text-body text-decoration-none" :to="{name:'buy'}">
              {{ FA_LAN.BUY_USER_COUNT }}: {{ summeryData.buyUser }}
            </router-link>
          </p>
        </div>
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p>{{ FA_LAN.BUY_MARKET_AMOUNT }}</p>
          <p class="text-start" v-for="marketData in summeryData.amount" :key="marketData.market">
            {{ marketData.market }}: {{ numberWithCommas(marketData.total) }}</p>
        </div>
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p>{{ FA_LAN.BUY_RATE_COUNT }}: {{ summeryData.countRate }}</p>
          <p>{{ FA_LAN.BUY_RATE_AMOUNT }}: {{ numberWithCommas(summeryData.buyRate) }}</p>
        </div>
        <div class="card shadow-me col-md-3 p-4 m-2">
          <router-link class="btn btn-link btn-sm text-body text-decoration-none text-end" :to="{name:'walletReport'}">
            <p>{{ FA_LAN.TOTAL_WALLET_GIFTS }}: {{ numberWithCommas(summeryData.walletGifts) }}</p>
            <p>{{ FA_LAN.TOTAL_WALLET_EXPIRES }}: {{ numberWithCommas(summeryData.walletExpires) }}</p>
            <p>{{ FA_LAN.TOTAL_WALLET_USED }}: {{ numberWithCommas(summeryData.walletUses) }}</p>
            <p>{{ FA_LAN.TOTAL_WALLET_PAY_FOR_USED }}: {{ numberWithCommas(summeryData.payForWalletUse) }}</p>
          </router-link>
        </div>
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p>{{ FA_LAN.USER_REPEAT_BUY }}</p>
          <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
          <table v-else class="table table-striped table-hover">
            <thead class="thead-dark">
            <tr>
              <th scope="col">{{ FA_LAN.BUY_PLAN_COUNT }}</th>
              <th scope="col">{{ FA_LAN.USER_COUNT }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in summeryData.userBuyRepeatCount" :key="index">
              <td>{{ item.planCount }}</td>
              <td>{{ numberWithCommas(item.userCount) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card shadow-me table-responsive col-md-8 p-4 mt-2">
        <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
        <table v-else class="table table-hover table-striped">
          <thead>
          <tr>
            <th scope="col">{{ FA_LAN.BUY_KIND }}</th>
            <th scope="col">{{ FA_LAN.BUY_COUNT }}</th>
            <th scope="col">{{ FA_LAN.BUY_AMOUNT }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(plan,index) in summeryData.count" :key="index">
            <td>{{ getPlanOrEducation(plan) }}</td>
            <td>{{ plan.count }}</td>
            <td>{{ numberWithCommas(Math.round(plan.sum)) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {onMounted, reactive, ref} from "vue";
import persian from "../fa-IR/fa-ir";
import {getPost} from "../js/api/restAPI";
import {sweetAlert} from "../js/alert/sweetAlert";
import {planOrEducationName, thousandCommas} from "../js/functions";
import DatePicker from "vue3-persian-datepicker";
import {jalali_to_gregorian} from "../js/date/convert";

export default {
  name: "Home",
  components: {
    DatePicker
  },
  setup() {
    const loading = ref(true);
    const formLoader = ref(false);
    const summeryData = ref({});
    const FA_LAN = persian;
    const date = reactive({
      begin: '',
      end: ''
    });
    const data = reactive({
      begin_date: '',
      end_date: ''
    });
    const empty = ref(true);


    onMounted(() => {
      getReport();
    })

    async function getReport(data = null) {
      formLoader.value = true;
      loading.value = true;
      //get summery report
      await getPost('admin/report/summaryBuy', data).then(function (response) {
        if (response.data.code !== 200) {
          sweetAlert('error', response.data.description)
        } else {
          summeryData.value = response.data.data
        }

      }).catch(function (error) {
        sweetAlert('error', error);
      })

      loading.value = false;
      formLoader.value = false;
      checKEmptyObject();
    }

    function filter() {
      if (date.begin !== '') {
        data.begin_date = jalali_to_gregorian(date.begin);
      }

      if (date.end !== '') {
        data.end_date = jalali_to_gregorian(date.end);
      }

      getReport(data);
    }

    function clearFilter() {
      getReport();
    }

    function getPlanOrEducation(plan) {
      return planOrEducationName(plan)
    }

    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    function checKEmptyObject() {
      empty.value = summeryData.value.length === 0;
    }

    return {
      FA_LAN,
      loading,
      summeryData,
      date,
      formLoader,
      empty,
      getPlanOrEducation,
      numberWithCommas,
      filter,
      clearFilter
    }
  }
}
</script>

<style>
.datePicker input {
  font-size: 14px !important;
  color: #333 !important;
}
</style>