import {createStore} from 'vuex'
import apiKey from "./includes/apiKey";
import plan from "./includes/plan";
import mainCategory from "./includes/mainCategory";
import education from "./includes/education";
import packages from "./includes/packages";
import ticket from "./includes/ticket";
import subCategory from "./includes/subCategory";
import reviewRejectReason from "./includes/reviewRejectReason";
import reviews from "./includes/reviews";
import reviewLottery from "./includes/reviewLottery";

const store = createStore({
    modules: {
        apiKey,
        plan,
        mainCategory,
        education,
        packages,
        ticket,
        subCategory,
        reviewRejectReason,
        reviews,
        reviewLottery
    }
})


export default store;