<template>
  <div class="d-flex justify-content-center">
    <form class="col-md-8 shadow bg-white p-3 rounded-3">
      <div class="mb-3">
        <TinyEditor v-model.lazy="form.text"/>
        <div class="form-text text-danger">{{ textError }}</div>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.FILE }}</label>
        <input class="form-control form-control-sm" type="file" @change="fileUpload">
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">
          </div>
          {{ FA_LAN.SEND_RESPONSE }}
        </button>
        <button type="submit" class="btn btn-outline-danger btn-sm" @click.prevent="close"
                :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">
          </div>
          {{ FA_LAN.CLOSE_TICKET }} ({{ closeCount }})
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {reactive, ref, watch} from "vue";
import TinyEditor from "./TinyEditor";
import persian from "../fa-IR/fa-ir";

export default {
  name: "TicketRespons",
  props: {
    textError: String,
    formLoader: Boolean,
    cleanForm: Boolean,
  },
  components: {
    TinyEditor,
  },
  setup(props, {emit}) {
    const form = reactive({
      text: '',
      file: ''
    })
    const FA_LAN = persian;
    const tinyAPiKey = 'yedw0q2sjtv1clyys6ydch93bcoespdx96m6nbsii66pobed';
    const closeCount = ref(3);

    function submit() {
      emit('formData', form)
    }

    function fileUpload(file) {
      form.file = file.target.files;
    }

    function close() {
      if (closeCount.value === 0) {
        emit('close')
      } else {
        closeCount.value--;
      }
    }

    watch(props, () => {
      if (props.cleanForm == true) {
        form.text = '';
        form.file = ''
      }
    })

    return {form, tinyAPiKey, FA_LAN, closeCount, submit, fileUpload, close}
  }
}
</script>

<style scoped>

</style>