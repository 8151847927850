<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8 card shadow-me p-3">
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.PACKAGE_NAME }}</label>
          <select class="form-control" v-model.lazy="education.package_id">
            <option v-for="packageItem in packages" :value="packageItem.id" :key="packageItem.id">
              {{ packageItem.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="mb-3">
        <div class="form-group">
          <label class="form-label">{{ FA_LAN.CATEGORY_TITLE }}</label>
          <select class="form-control" v-model.lazy="mainCategory">
            <option v-for="mainCategory in mainCategories" :key="mainCategory.id" :value="mainCategory.code">
              {{ mainCategory.title }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="mb-3 col-md-4">
          <label class="form-label">{{ FA_LAN.SEARCH }}</label>
          <input class="form-control" type="text" v-model.lazy="search"/>
        </div>
        <div class="mb-3 col-md-8">
          <div class="form-group">
            <label class="form-label">{{ FA_LAN.EDUCATION_TITLE }}</label>
            <select class="form-control" v-model.lazy="education.education_id">
              <option v-for="education in educations" :key="education.id" :value="education.id">
                {{ education.education_number }} - {{ education.title }}
              </option>
            </select>
            <div class="spinner-border spinner-border-sm" role="status" v-if="moreLoader"></div>
          </div>
        </div>
      </div>
      <!--      <div class="form-text text-danger">{{ textError }}</div>-->
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.EDUCATION_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import persian from "../../../fa-IR/fa-ir";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const education = reactive({
      package_id: "",
      education_id: "",
    })
    const mainCategory = ref('');
    const search = ref('');
    const index = ref(0);
    const store = useStore();
    const packages = computed(() => store.getters["packages/get"]);
    const mainCategories = computed(() => store.getters["mainCategory/get"]);
    const educations = computed(() => store.getters["education/get"]);
    const formLoader = ref(false);
    const moreLoader = ref(false);
    const FA_LAN = persian;


    async function submit() {
      formLoader.value = true

      await getPost('admin/education/package/new', education)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    watch(search, async () => {
      moreLoader.value = true;
      index.value = 0;
      await getVideos();

      await getAllEducaton();
    })

    async function getVideos() {
      await store.dispatch('education/fetch', {
        index: index.value,
        mainCategory: mainCategory,
        search: search.value,
      })

      moreLoader.value = false;
    }

    async function getAllEducaton() {
      if (educations.value.length === 0) {
        return;
      }
      while (educations.value.length % 10 === 0) {
        educations.value.length
        moreLoader.value = true;
        index.value += 10;
        await getVideos();
      }
    }

    return {
      education,
      packages,
      formLoader,
      FA_LAN,
      mainCategories,
      educations,
      search,
      moreLoader,
      mainCategory,
      submit
    }
  }
}
</script>

<style scoped>

</style>