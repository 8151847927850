<template>
  <div>
    <div class="d-flex justify-content-center mt-lg-5 mb-5">
      <form class="col-md-8  card shadow-me p-3">
        <div class="mb-3">
          <label class="form-label">{{ FA_LAN.REJECT_REASON }}</label>
          <input class="form-control" type="text" v-model.lazy="reason.text"/>
          <div class="form-text text-danger">{{ textError }}</div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
            <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
            {{ FA_LAN.NEW_REASON }}
          </button>
        </div>
      </form>
    </div>
    <div class="table-responsive col-md-8 mx-auto">
      <table class="table table-striped table-hover">
        <thead class="thead-dark">
        <tr>
          <th scope="col">{{ FA_LAN.REJECT_REASON }}</th>
        </tr>
        </thead>
        <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
        <tbody v-else>
        <tr v-for="reason in reviewRejectReasons" :key="reason.id">
          <td>{{ reason.reason }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {computed, reactive, ref} from "vue";
import persian from "@/fa-IR/fa-ir";
import {getPost} from "@/js/api/restAPI";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {useStore} from "vuex";

export default {
  name: "AddReason",
  setup() {
    const reason = reactive({
      text: '',
    })

    const FA_LAN = persian;
    const formLoader = ref(false)
    const textError = ref('');
    const store = useStore();
    const reviewRejectReasons = computed(() => store.getters["reviewRejectReason/get"]);
    const refresh = ref(false);

    async function submit() {

      if (reason.text.length === 0) {
        textError.value = FA_LAN.ERRORS.REASON_EMPTY;
        return
      }

      textError.value = '';

      formLoader.value = true;

      await getPost(`admin/reviews/addRejectReason`, reason)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);

              refresh.value = true;
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })
      reason.text = '';

      if (refresh.value) {
        await store.dispatch('reviewRejectReason/fetch');
      }

      formLoader.value = false;
    }

    return {reason, FA_LAN, textError, formLoader, reviewRejectReasons, submit}
  }
}
</script>

<style scoped>

</style>