import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const reviewRejectReason = {
    namespaced: true,
    state: {
        reasons: [],
    },
    getters: {
        get(state) {
            return state.reasons;
        }
    },
    mutations: {
        set(state, reasons) {
            state.reasons = reasons;
        }
    },
    actions: {
        async fetch({commit}) {

            await getPost('admin/reviews/getRejectReason', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        commit("set", response.data.data.reasons);

                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default reviewRejectReason