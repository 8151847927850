<template>
  <div class="offcanvas offcanvas-bottom" style="height: 50% !important;" tabindex="-1" id="lottery"
       aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header justify-content-center col-md-6 mx-auto">
      <div class="row all-card-size col-11">
        <div class="d-grid gap-2 d-md-flex justify-content-md-center">
          <div class="row mb-4" v-if="!empty">
            <div class="col animate__animated animate__fadeInDown" v-for="review in winner" :id="'canvas'+review.id"
                 :key="review.id">
              <div class=" card shadow-me mt-3 ms-2">
                <div class="row text-black-50 ps-2 pe-2 pt-2 dir-ltr">
                  <p class="col-7">{{ tojalali(review.date) }} </p>
                  <p class="col-5">{{ review.phone }}</p>
                </div>
                <div class="row ps-2 pe-2">
                  <p class="col-9 small" :class="{'text-black-50':review.text===null}">{{ getText(review.text) }}</p>
                  <p class="golden-text col-3">{{ review.rate }} <i class="bi bi-star-fill"></i></p>
                </div>
                <div class="small card-header">
                  {{ getCategoryName(review.main_category) }}
                </div>
              </div>
              <h2 class="text-center mt-3" style="display: none" :id="'tick'+review.id">
                <i class="text-success bi bi-check2-square"></i>
              </h2>
            </div>
          </div>
        </div>
        <div class="row text-center" id="btnSms" style="display: none">
          <button type="submit" class="btn btn-dark ps-5 pe-5 col-8 mx-auto" @click.prevent="sendSms"
                  :disabled="formLoader">
            <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
            {{ FA_LAN.SUBMIT_LOTTERY_WINNER }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, reactive, ref, watch} from "vue";
import {gregorian_to_jalali} from "@/js/date/convert";
import {getPost} from "@/js/api/restAPI";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "ReviewLottery",
  props: ['start'],
  setup(props) {
    const FA_LAN = persian;
    const store = useStore();
    const reviews = computed(() => store.getters["reviewLottery/get"]);
    const allCount = computed(() => store.getters["reviewLottery/getAllCount"]);
    const lotteryCount = computed(() => store.getters["reviewLottery/getLotteryCount"]);
    const formLoader = ref(false);
    const intervalId = ref(0);
    const winner = ref([]);
    const empty = ref(true);
    const lastID = ref(null);
    const intervalIdBtn = ref(0);
    const tickID = ref(null);
    const saveWinner = reactive({
      'user_ids': []
    })

    const lotteryOptions = reactive({
      tryCount: 1,
      lotteryCount: 1,
      maxTry: 5
    })

    watch(props, () => {
      checKEmptyObject();

      if (props.start) {
        intervalId.value = window.setInterval(() => {
          startLottery();
        }, 1000)
      }
    })

    function startLottery() {
      if (lotteryOptions.tryCount > lotteryOptions.maxTry) {
        lotteryOptions.lotteryCount++;
        lotteryOptions.tryCount = 1;
      }

      if (lotteryOptions.lotteryCount == lotteryCount.value && lotteryOptions.tryCount === lotteryOptions.maxTry) {
        stopInterval();
      }

      const index = getRandomInt()
      const id = getID(index);

      showTick();

      changeItem(id, index)

      checKEmptyObject();

      lotteryOptions.tryCount++;
    }

    function stopInterval() {
      clearInterval(intervalId.value);

      intervalIdBtn.value = window.setInterval(() => {
        showSmsBtn();
      }, 1000)
    }

    function showTick() {

      if (tickID.value) {
        const element = document.getElementById('tick' + tickID.value);
        element.classList.add('animate__animated', 'animate__zoomIn')
        element.style.display = "block";
      }
    }

    function getID(index) {
      return reviews.value[index]['id'];
    }

    function changeItem(id, index) {
      winner.value.unshift(reviews.value[index]);

      if (lastID.value) {
        const review = winner.value.find(review => review.id === lastID.value);
        const lastIndex = winner.value.indexOf(review);

        if (lastIndex >= 0) {
          winner.value.splice(lastIndex, 1);
        }

        if (lotteryOptions.tryCount === lotteryOptions.maxTry) {

          lastID.value = null;
          tickID.value = id;

        } else {

          lastID.value = id;
          tickID.value = null;
        }

      } else {
        lastID.value = id;
      }


    }

    function showSmsBtn() {
      showTick();

      let element;
      element = document.getElementById('btnSms');
      element.classList.add('animate__animated', 'animate__fadeInUp');
      element.style.display = 'block'

      clearInterval(intervalIdBtn.value)
    }

    function getRandomInt() {
      return Math.floor(Math.random() * reviews.value.length);
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function getCategoryName(code) {
      let category;
      category = store.getters["mainCategory/filter"](code);

      if (!category) {
        category = store.getters["packages/filter"](code);
      }

      return category ? category.title : '';
    }

    function getText(text) {
      let outputText;
      if (text === null) {
        outputText = FA_LAN.NOT_TEXT;

      } else if (text.length > 25) {
        outputText = text.substr(0, 25) + "..."

      } else {
        outputText = text.substr(0, 25);

      }

      return outputText;
    }

    function checKEmptyObject() {
      empty.value = winner.value.length === 0;
    }

    async function sendSms() {
      formLoader.value = true;

      saveWinner.user_ids = winner.value.map(item => item.user_id);

      await getPost('admin/reviews/saveLotteryWinner', saveWinner)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              sweetAlert('success', response.data.description)
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    return {
      FA_LAN,
      reviews,
      allCount,
      lotteryCount,
      formLoader,
      winner,
      empty,
      sendSms,
      tojalali,
      getCategoryName,
      getText,
    }
  }
}
</script>

<style scoped>
.golden-text {
  color: #FFA500;
}

.card-header {
  border-bottom: none;
}

.card {
  min-width: 250px !important;
}

.dir-ltr {
  direction: ltr !important;
}
</style>