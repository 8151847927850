<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8  card shadow-me p-3">
      <div class="form-check form-switch mb-3">
        <label class="form-check-label" for="isTemplate">{{ FA_LAN.IS_TEMP }}</label>
        <input type="checkbox" class="form-check-input" id="isTemplate" v-model="off.is_temp">
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_TITLE }}</label>
        <input class="form-control" type="text" v-model.lazy="off.title"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_DESCRIPTION }}</label>
        <input class="form-control" type="text" v-model.lazy="off.description"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_IMAGE }}</label>
        <input class="form-control" type="text" v-model.lazy="off.image"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.VALID_DATE_COUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="off.valid_date"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_LIMIT_COUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="off.limit_count"/>
      </div>
      <div class="form-check form-switch mb-3">
        <label class="form-check-label" for="isPercentage">{{ FA_LAN.OFF_IS_PERCENTAGE }}</label>
        <input type="checkbox" class="form-check-input" id="isPercentage" v-model="off.percentage">
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.OFF_PLAN_AMOUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="off.amount"/>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.SUBMIT_OFF }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {reactive, ref} from "vue";
import {getPost,} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import persian from "../../fa-IR/fa-ir";

export default {
  name: "New",
  setup() {
    const off = reactive({
      title: "",
      description: "",
      image: "",
      valid_date: "",
      plan_id: "",
      limit_count: 1,
      percentage: true,
      amount: "",
      is_temp: true
    })
    const FA_LAN = persian;
    const formLoader = ref(false);

    async function submit() {
      formLoader.value = true;

      //change true false to 1 and 0
      if (!off.percentage) {
        off.percentage = 0;
      }

      if (!off.is_temp) {
        off.is_temp = 0;
      }

      await getPost('admin/off/new', off)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success', response.data.description);
            }

          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    return {off, formLoader, FA_LAN, submit};
  }

}
</script>

<style scoped>
.form-check .form-check-input {
  float: right !important;
  margin-right: 1.5em !important;
  margin-left: 0.5em !important;
}
</style>