<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex col-md-8">
        <div class="ms-4">
          <router-link class="btn btn-dark btn-sm pe-3 ps-3" :to="{name:'newTicketCategories'}">
            {{ FA_LAN.NEW_TICKET_CATEGORIES }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-center mt-3">
        <div class="card shadow-me p-3 table-responsive col-md-10">
          <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
          <table v-else class="table table-hover table-striped">
            <thead>
            <tr>
              <th scope="col">{{ FA_LAN.TICKET_CATEGORY }}</th>
              <th scope="col">{{ FA_LAN.TICKET_DESCRIPTION }}</th>
              <th scope="col">{{ FA_LAN.TICKET_AMOUNT }}</th>
              <th scope="col">{{ FA_LAN.TICKET_MIN_AMOUNT }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="category in categories" :key="category.id">
              <td>{{ category.title }}</td>
              <td>{{ category.description }}</td>
              <td>{{ numberWithCommas(category.amount) }}</td>
              <td>{{ numberWithCommas(category.min_amount) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../../fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import {thousandCommas} from "../../../js/functions";

export default {
  name: "Category",
  setup() {
    const FA_LAN = persian;
    const categories = ref([]);
    const loading = ref(true);
    const empty = ref(true);

    onMounted(() => {
      getTicketCategory();
    })

    async function getTicketCategory() {
      await getPost('ticket/getTicketCategory', null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              categories.value = response.data.data.categories;
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      loading.value = false;
      checKEmptyObject();
    }

    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    function checKEmptyObject() {
      empty.value = categories.value.length === 0;
    }

    return {FA_LAN, categories, loading, empty, numberWithCommas}
  }
}
</script>

<style scoped>

</style>