import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const reviews = {
    namespaced: true,
    state: {
        reviews: [],
        allCount: 0,
        lotteryCount: 0,
    },
    getters: {
        get(state) {
            return state.reviews;
        },
        getAllCount(state) {
            return state.allCount;
        },
        getLotteryCount(state) {
            return state.lotteryCount;
        }
    },
    mutations: {
        set(state, reviews) {
            state.reviews = reviews;
        },
        setAllCount(state, count) {
            state.allCount = count;
        },
        setLotteryCount(state, lotteryCount) {
            state.lotteryCount = lotteryCount;
        }
    },
    actions: {
        async fetch({commit}) {

            await getPost('admin/reviews/getAllAccepted', null)
                .then(function (response) {
                    if (response.data.code === 200) {
                        commit("set", response.data.data.reviews);
                        commit("setAllCount", response.data.data.count);
                        commit("setLotteryCount", response.data.data.lottery_count);
                    } else {
                        sweetAlert('error', response.data.description)
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default reviews