<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="mb-4" ref='scrollComponent'>
      <div class="row d-flex align-items-start">
        <div class="row col-md-4">
          <div class="card shadow-me col-md-3 p-4 m-2">
            <p class="m-0">{{ FA_LAN.CREDIT }}</p>
            <p class="text-start" :class="chargeClass">{{ numberWithCommas(credit) }}{{ FA_LAN.RIAL }}</p>
          </div>
          <div class="card shadow-me col-md-3 p-4 m-2">
            <div>
              <label class="form-label">{{ FA_LAN.AMOUNT }}</label>
              <input class="form-control" type="number" v-model="chargeAmount">
              <small class="text-secondary pt-2" v-if="chargeAmountTax">
                {{ FA_LAN.WITH_TAX }}{{ numberWithCommas(chargeAmountTax) }}{{ FA_LAN.TOMAN }}
              </small>
            </div>
            <button class="btn btn-sm btn-dark text-center col-12 mt-3" @click="getPayLink" :disabled="formLoader">
              <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
              {{ FA_LAN.CHARGE }}
            </button>
          </div>
          <div class="card shadow-me col-md-3 p-4 m-2">
            <p>{{ FA_LAN.CHARGE_LOG }}</p>
            <div class="table-responsive small text-muted">
              <p v-if="emptyPayLog">{{ FA_LAN.NO_DATA }}</p>
              <table v-else class="table table-striped table-sm">
                <thead>
                <tr>
                  <th class="col">{{ FA_LAN.DATE }}</th>
                  <th class="col">{{ FA_LAN.AMOUNT }}</th>
                  <th class="col">{{ FA_LAN.REFID }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="pay in payLogs" :key="pay.id">
                  <td>{{ tojalali(pay.createDate) }}</td>
                  <td>{{ numberWithCommas(pay.amount) }}</td>
                  <td>{{ pay.refid }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card shadow-me table-responsive col-md-8 p-4 pb-0 mt-2">
          <p v-if="emptySmsLog">{{ FA_LAN.NO_DATA }}</p>
          <table v-else class="table table-hover table-striped small">
            <thead>
            <tr>
              <th scope="col">{{ FA_LAN.TEXT }}</th>
              <th scope="col">{{ FA_LAN.PHONE }}</th>
              <th scope="col">{{ FA_LAN.OPERATOR }}</th>
              <th scope="col">{{ FA_LAN.AMOUNT }}</th>
              <th scope="col">{{ FA_LAN.DATE }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="sms in smsLogs" :key="sms.id">
              <td>{{ sms.text }}</td>
              <td>{{ sms.toPhone }}</td>
              <td>{{ sms.operator }}</td>
              <td>{{ Math.round(sms.totalPrice) }}</td>
              <td>{{ tojalali(sms.createDate) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center mt-3 mb-3" v-if="moreLoader">
          <div class="spinner-border" role="status" v-if="moreLoader"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {onMounted, onUnmounted, ref, watch} from "vue";
import {sweetAlert} from "../js/alert/sweetAlert";
import {getPost, smsPost} from "../js/api/restAPI";
import {gregorian_to_jalali} from "../js/date/convert";
import {thousandCommas} from "../js/functions";

export default {
  name: "Sms",
  setup() {
    const FA_LAN = persian;
    const smsKey = ref('');
    const loading = ref(true);
    const credit = ref(0);
    const payLogs = ref([]);
    const smsLogs = ref([]);
    const smsIndex = ref(0);
    const chargeClass = ref('');
    const scrollComponent = ref('');
    const moreLoader = ref(false);
    const chargeAmount = ref('');
    const chargeAmountTax = ref(0);
    const formLoader = ref(false);
    const emptyPayLog = ref(true);
    const emptySmsLog = ref(true);

    onMounted(async () => {
      await getSmsKey();
      await getCredit();
      await getPayLog();
      await getSmsLog();

      getChargeClass();
      loading.value = false
      moreLoader.value = false;
      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function getSmsKey() {
      await getPost('admin/smsKey')
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              smsKey.value = response.data.data.sms_key;
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })
    }

    async function getCredit() {
      await smsPost('getCredit', null, smsKey.value)
          .then(function (response) {
            credit.value = Number.parseInt(response.data.credit);
          }).catch(function (error) {
            sweetAlert('error', error)
          })
    }


    async function getPayLog() {
      await smsPost('getPayLog', null, smsKey.value)
          .then(function (response) {
            payLogs.value.push(...response.data.log);
          }).catch(function (error) {
            sweetAlert('error', error)
          })
      checKEmptyObjectPayLogs();
    }

    async function getSmsLog() {
      await smsPost(`getSmsLog?index=${smsIndex.value}`, null, smsKey.value)
          .then(function (response) {
            smsLogs.value.push(...response.data.log);
          }).catch(function (error) {
            sweetAlert('error', error)
          })
      checKEmptyObjectSmsLogs();
    }

    async function getPayLink() {
      if (chargeAmount.value < 50000) {
        sweetAlert('error', FA_LAN.ERRORS.LOW_CHARGE_PRICE);
        return;
      }

      formLoader.value = true;

      chargeAmount.value = chargeAmount.value / 10; //to toman

      await smsPost(`getPayURL`, {
        amount: chargeAmount.value,
        callBack: location.href
      }, smsKey.value)
          .then(function (response) {
            location.href = response.data.url
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    watch(chargeAmount, () => {
      chargeAmountTax.value = (chargeAmount.value * 1.09) / 10
      chargeAmountTax.value = Number.parseInt(chargeAmountTax.value);
    })

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }


    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    function getChargeClass() {
      if (credit.value < 100000) {
        chargeClass.value = 'text-danger'
      } else {
        chargeClass.value = 'text-success'
      }
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (smsIndex.value - smsLogs.value.length > 20) { //for stop looping
          return;
        }
        moreLoader.value = true;
        smsIndex.value += 20;
        await getSmsLog();
      }
    }

    function checKEmptyObjectPayLogs() {
      emptyPayLog.value = payLogs.value.length === 0;
    }

    function checKEmptyObjectSmsLogs() {
      emptySmsLog.value = smsLogs.value.length === 0;
    }

    function checkPreviousReceive() {
      return smsLogs.value.length === smsIndex.value + 20;
    }


    return {
      FA_LAN,
      loading,
      credit,
      payLogs,
      smsLogs,
      chargeClass,
      scrollComponent,
      moreLoader,
      chargeAmount,
      chargeAmountTax,
      formLoader,
      emptyPayLog,
      emptySmsLog,
      tojalali,
      numberWithCommas,
      getPayLink
    }
  }
}
</script>

<style scoped>

</style>