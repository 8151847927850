<template>
  <div v-if="showBanner" class="toast-container position-absolute p-3 bottom-0 start-50 translate-middle-x dir-rtl">
    <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong class="mx-auto">{{ FA_LAN.BANNER_DES }}</strong>
      </div>
      <div class="toast-body">
        <div class="mx-auto text-center">
          <button type="button" class="btn btn-success btn-sm ms-2" @click="install">{{ FA_LAN.INSTALL_NOW }}</button>
          <button type="button" class="btn btn-outline-secondary btn-sm" data-bs-dismiss="toast" @click="disMissBanner">
            {{ FA_LAN.DENY }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "@/fa-IR/fa-ir";
import {onMounted, ref} from "vue";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "PWABanner",
  setup() {
    const FA_LAN = persian;
    const installPromptEvent = ref(null);
    const showBanner = ref(false);

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault(); //dont show automatic banner in old browser

        if (checkDeviceIsMobile() && !checkPWAInstalled()) {
          installPromptEvent.value = e; //add event to variable for use

          showBanner.value = true
        }
      })
    })

    function install() {
      installPromptEvent.value.prompt(); //install pwa

      //get user action
      installPromptEvent.value.userChoice.then((choiceResult) => {

        if (choiceResult.outcome === 'accepted') {
          saveInstalledPwa();
          sweetAlert('success', FA_LAN.sweetAlert.PWA_THANKS)
        } else {
          sweetAlert('warning', FA_LAN.sweetAlert.PWA_DENIED)
        }

        //empty variable after user choice his action,it used and we can use again
        installPromptEvent.value = null;

        showBanner.value = false
      })

    }

    function disMissBanner() {
      showBanner.value = false;
    }

    function checkDeviceIsMobile() {
      let isMobile = false; //initiate as false

      // device detection
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true
      }

      return isMobile;
    }

    function saveInstalledPwa() {
      localStorage.setItem('pwaInstall', JSON.stringify(true));
    }

    function checkPWAInstalled() {
      return localStorage.getItem('pwaInstall');
    }

    return {FA_LAN, showBanner, install, disMissBanner}
  }
}
</script>

<style scoped>

</style>