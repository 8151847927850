<template>
  <Editor :init="{
            menubar: false,
            directionality:'rtl',
            content_style:'body { font-family: iransans; font-size:12px }',
            plugins: 'lists link',
            toolbar: 'styleselect | bold italic forecolor backcolor| alignleft aligncenter alignright alignjustify | bullist numlist | link',
            mobile: {
              theme: 'mobile'
                },
          }"
  />
</template>

<script>
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/themes/mobile";
import "tinymce/icons/default";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/skins/ui/oxide/skin.mobile.css";

import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TinyEditor",
  components: {
    Editor
  },
  setup() {
    // const tinyAPiKey = 'yedw0q2sjtv1clyys6ydch93bcoespdx96m6nbsii66pobed';

    return {}
  }
}
</script>

<style scoped>

</style>