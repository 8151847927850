<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else ref='scrollComponent'>
      <div class="d-flex justify-content-center mt-lg-5 row">
        <form class="col-md-8">
          <div class="row">
            <div class="mb-3 col-6">
              <label class="form-label">{{ FA_LAN.PHONE }}</label>
              <input class="form-control" type="number" v-model.lazy="data.phone"/>
              <div class="form-text text-danger">{{ textError }}</div>
            </div>
            <div class="col-6 d-flex justify-content-sm-start align-items-center ">
              <button type="submit" class="btn btn-dark btn-sm pe-3 ps-3" @click.prevent="search"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader">
                </div>
                {{ FA_LAN.RECEIVING_DATA }}
              </button>
            </div>
          </div>
        </form>
        <div class="col-md-3 d-flex justify-content-end align-items-end">
          <router-link class="btn btn-success  btn-sm pe-3 ps-3" :to="{name:'walletTransactions'}">
            {{ FA_LAN.WALLET_TRANSACTIONS }}
          </router-link>
          <router-link class="btn btn-outline-secondary  btn-sm pe-3 ps-3 me-2" :to="{name:'walletReport'}">
            {{ FA_LAN.WALLET_REPORT }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="card shadow-me table-responsive col-md-8 p-4 m-2 mx-auto">
        <table class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.PHONE }}</th>
            <th scope="col">{{ FA_LAN.TOTAL_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.UPDATE_DATE }}</th>
          </tr>
          </thead>
          <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
          <tbody v-else>
          <tr v-for="wallet in wallets" :key="wallet.id">
            <td>{{ wallet.phone }}</td>
            <td>{{ numberWithCommas(wallet.amount) }}</td>
            <td>{{ tojalali(wallet.updated_at) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center mt-3 mb-3" v-if="loadMoreButton">
        <div class="spinner-border" role="status" v-if="moreLoader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {onMounted, onUnmounted, reactive, ref} from "vue";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {gregorian_to_jalali} from "../../js/date/convert";
import {thousandCommas} from "../../js/functions";

export default {
  name: "Amount",
  setup() {
    const FA_LAN = persian;
    const formLoader = ref(false)
    const loading = ref(true)
    const moreLoader = ref(false)
    const empty = ref(true);
    const loadMoreButton = ref(true);
    const index = ref(0);
    const scrollComponent = ref(null);
    const wallets = ref([]);
    const data = reactive({
      phone: ""
    })

    onMounted(async () => {
      await getAmountList();
      await loadMore();//for large screen

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function getAmountList() {
      await getPost(`admin/wallet/getAmount?index=${index.value}`, data)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              wallets.value.push(...response.data.data.wallets); //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      loading.value = false;
      moreLoader.value = false;
      formLoader.value = false
    }

    async function search() {
      loading.value = true
      formLoader.value = true;

      index.value = 0;
      wallets.value = [];

      await getAmountList();
      await loadMore();//for large screen
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - wallets.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getAmountList();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = wallets.value.length === 0;
    }

    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    function checkPreviousReceive() {
      return wallets.value.length === index.value + 10;
    }

    return {
      FA_LAN,
      loadMoreButton,
      moreLoader,
      loading,
      wallets,
      formLoader,
      scrollComponent,
      data,
      empty,
      numberWithCommas,
      tojalali,
      search
    }
  }
}
</script>

<style scoped>

</style>