<template>
  <div>
    <div class="row col-md-12">
      <div class="row col-md-8">
        <div class="col-md-4 mt-2">
          <label>{{ FA_LAN.BEGIN_DATE }}</label>
          <DatePicker v-model="date.begin"/>
        </div>
        <div class="col-md-4 mt-2">
          <label>{{ FA_LAN.END_DATE }}</label>
          <DatePicker v-model="date.end"/>
        </div>
        <div class="col-md-4 d-flex justify-content-sm-start align-items-end mt-2">
          <button type="submit" class="btn btn-dark btn-sm pe-3 ps-3" @click.prevent="filter" :disabled="formLoader">
            {{ FA_LAN.FILTER }}
          </button>
          <button type="submit" class="btn btn-outline-secondary btn-sm pe-3 ps-3 me-4" @click.prevent="clearFilter"
                  :disabled="formLoader">
            {{ FA_LAN.DELETE }}
          </button>
        </div>
      </div>
      <div class="col-md-4 d-flex justify-content-end align-items-end">
        <router-link class="btn btn-dark btn-sm pe-3 ps-3 ms-2" :to="{name:'walletTransactions'}">
          {{ FA_LAN.WALLET_TRANSACTIONS }}
        </router-link>
        <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'walletAmounts'}">
          {{ FA_LAN.WALLET_AMOUNT }}
        </router-link>
      </div>
    </div>
    <hr>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="row d-flex align-items-start">
      <div class="row col-md-4">
        <div class="card shadow-me col-md-3 p-4 m-2">
          <p>{{ FA_LAN.TOTAL_WALLET_GIFTS }}: {{ numberWithCommas(summaryData.walletGifts) }}</p>
          <p>{{ FA_LAN.TOTAL_WALLET_EXPIRES }}: {{ numberWithCommas(summaryData.walletExpires) }}</p>
          <p>{{ FA_LAN.TOTAL_WALLET_USED }}: {{ numberWithCommas(summaryData.walletUses) }}</p>
          <p>{{ FA_LAN.TOTAL_WALLET_PAY_FOR_USED }}: {{ numberWithCommas(summaryData.payForWalletUse) }}</p>
        </div>
      </div>
      <div class="card shadow-me table-responsive col-md-8 p-4 mt-2">
        <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
        <table v-else class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.TYPE_TITLE }}</th>
            <th scope="col">{{ FA_LAN.TOTAL_COUNT }}</th>
            <th scope="col">{{ FA_LAN.TOTAL_AMOUNT }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) in summaryData.typeReport" :key="index">
            <td>{{ item.type_title }}</td>
            <td>{{ numberWithCommas(item.count) }}</td>
            <td :class="{'text-danger':!item.is_increase}">{{ numberWithCommas(item.sum) }}</td>
          </tr>
          <tr class="table-dark text-center">
            <td colspan="3">{{ FA_LAN.COUPONS }}</td>
          </tr>
          <tr v-for="(item,index) in summaryData.couponReport" :key="index">
            <td>{{ item.description }}</td>
            <td>{{ numberWithCommas(item.count) }}</td>
            <td>{{ numberWithCommas(item.sum) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {onMounted, reactive, ref} from "vue";
import {jalali_to_gregorian} from "../../js/date/convert";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import DatePicker from "vue3-persian-datepicker";
import {thousandCommas} from "../../js/functions";

export default {
  name: "Report",
  components: {DatePicker},
  setup() {
    const FA_LAN = persian;
    const formLoader = ref(false);
    const loading = ref(true);
    const summaryData = ref(null);
    const empty = ref(true);
    const date = reactive({
      begin: '',
      end: ''
    });
    const data = reactive({
      begin_date: '',
      end_date: ''
    });

    onMounted(() => {
      getReport();
    })

    function filter() {
      if (date.begin !== '') {
        data.begin_date = jalali_to_gregorian(date.begin);
      }

      if (date.end !== '') {
        data.end_date = jalali_to_gregorian(date.end);
      }

      getReport(data);
    }

    function clearFilter() {
      getReport();
    }

    async function getReport(data = null) {
      formLoader.value = true;
      loading.value = true;
      //get summery report
      await getPost('admin/wallet/summaryWallet', data).then(function (response) {
        if (response.data.code !== 200) {
          sweetAlert('error', response.data.description)
        } else {
          summaryData.value = response.data.data
        }

      }).catch(function (error) {
        sweetAlert('error', error);
      })

      loading.value = false;
      formLoader.value = false;
      checKEmptyObject();
    }

    function checKEmptyObject() {
      empty.value = summaryData.value.couponReport.length === 0 && summaryData.value.typeReport.length === 0;
    }

    function numberWithCommas(value) {
      return thousandCommas(value);
    }

    return {FA_LAN, formLoader, date, summaryData, loading, empty, filter, numberWithCommas, clearFilter}
  }
}
</script>

<style scoped>

</style>