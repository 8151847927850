<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex col-md-8">
        <div class="ms-4">
          <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'newSubcategory'}">
            {{ FA_LAN.CATEGORY_SUBMIT }}
          </router-link>
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-center mt-3">
        <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
        <div v-else class="card shadow-me p-3 table-responsive col-md-10">
          <table class="table table-hover table-striped">
            <thead>
            <tr>
              <th scope="col">{{ FA_LAN.MAIN_CATEGORY }}</th>
              <th scope="col">{{ FA_LAN.CATEGORY_CODE }}</th>
              <th scope="col">{{ FA_LAN.SUB_CATEGORY }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="subCategory in subCategories" :key="subCategory.id">
              <td>{{ getName(subCategory.main_category) }}</td>
              <td>{{ subCategory.code }}</td>
              <td>{{ subCategory.title }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../../fa-IR/fa-ir";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";

export default {
  name: "SubCategories",
  setup() {
    const route = useRoute();
    const mainCategory = route.params.mainCategory;
    const store = useStore()
    const subCategories = computed(() => store.getters["subCategory/get"]);
    const mainCategoryDetail = computed(() => store.getters["mainCategory/filter"](mainCategory));
    const empty = ref(true);
    const FA_LAN = persian;
    const loading = ref(true);

    onMounted(async () => {
      await store.dispatch("subCategory/fetch", {mainCategory: mainCategory})
      checKEmptyObject();
      loading.value = false;
    })

    watch(subCategories, () => {
      checKEmptyObject();
    })

    function checKEmptyObject() {
      empty.value = subCategories.value.length === 0;
    }

    function getName(code) {
      if (code === mainCategoryDetail.value.code) {
        return mainCategoryDetail.value.title
      }
    }

    return {FA_LAN, subCategories, empty, loading, getName}
  }
}
</script>

<style scoped>
img {
  width: 35px;
  height: auto;
}
</style>