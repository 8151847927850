<template>
  <div v-if="loading" class="modal-me">
    <div class="my-modal-content">
      <div class="spinner-border " role="status"></div>
    </div>
  </div>
  <div v-else class="col-md-8 mx-auto mt-4" ref='scrollComponent'>
    <div class="col-md-4 d-flex justify-content-start align-items-start">
      <router-link class="btn btn-dark btn-sm pe-3 ps-3 ms-2" :to="{name:'addReason'}">
        {{ FA_LAN.NEW_REASON }}
      </router-link>
      <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'reviewLottery'}">
        {{ FA_LAN.SUBMIT_LOTTERY }}
      </router-link>
    </div>
    <hr>
    <div class="table-responsive">
      <table class="table table-striped table-hover">
        <thead class="thead-dark">
        <tr>
          <th scope="col">{{ FA_LAN.MAIN_CATEGORY }}</th>
          <th scope="col">{{ FA_LAN.REVIEW_COUNT }}</th>
          <th scope="col">{{ FA_LAN.RATE_COUNT }}</th>
          <th scope="col">{{ FA_LAN.RATE_AV }}</th>
        </tr>
        </thead>
        <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
        <tbody v-else>
        <tr v-for="review in reviews" :key="review.id" @click="goToReview(review.main_category)">
          <td><p class="m-0">
            {{ getCategoryName(review.main_category) }}
            <span v-if="review.new_review" class="badge bg-danger me-1">
                {{ review.new_review }}
            </span>
          </p>
          </td>
          <td>{{ review.review_count }}</td>
          <td>{{ review.rate_count }}</td>
          <td>{{ review.rate }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {onMounted, ref} from "vue";
import persian from "@/fa-IR/fa-ir";
import {getPost} from "@/js/api/restAPI";
import {sweetAlert} from "@/js/alert/sweetAlert";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "Summary",
  setup() {
    const loading = ref(true)
    const empty = ref(true);
    const reviews = ref([]);
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();

    async function getReviewsReport() {
      await getPost(`admin/reviews/getReport`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              reviews.value = response.data.data.reviews; //push for support add more

              checKEmptyObject();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      loading.value = false;
    }

    onMounted(() => {
      getReviewsReport();
    })

    function checKEmptyObject() {
      empty.value = reviews.value.length === 0;
    }

    function getCategoryName(code) {
      let category;
      category = store.getters["mainCategory/filter"](code);

      if (!category) {
        category = store.getters["packages/filter"](code);
      }

      return category ? category.title : '';
    }

    function goToReview(code) {
      router.push({name: 'showReviews', params: {mainCategory: code}})
    }

    return {
      empty,
      reviews,
      FA_LAN,
      loading,
      getCategoryName,
      goToReview,
    }
  }
}
</script>

<style scoped>

</style>