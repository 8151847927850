<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8  card shadow-me p-3">
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TICKET_TITLE }}</label>
        <input class="form-control" type="text" v-model.lazy="category.title"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TICKET_DESCRIPTION }}</label>
        <input class="form-control" type="text" v-model.lazy="category.description"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TICKET_AMOUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="category.amount"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.TICKET_MIN_AMOUNT }}</label>
        <input class="form-control" type="number" v-model.lazy="category.min_amount"/>
      </div>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.NEW_TICKET_CATEGORIES }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import persian from "../../../fa-IR/fa-ir";
import {reactive, ref} from "vue";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import {useRouter} from "vue-router";

export default {
  name: "New",
  setup() {
    const FA_LAN = persian;
    const formLoader = ref(false);
    const category = reactive({
      title: '',
      description: '',
      amount: '',
      min_amount: '',
    })
    const router=useRouter();

    async function submit() {
      formLoader.value = true

      await getPost('admin/ticket/newCategory', category)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success', response.data.description);
              router.push({name: 'ticketCategories'});
            }
          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    return {FA_LAN, formLoader, category, submit}
  }
}
</script>

<style scoped>

</style>