import FA_LAN from "../../fa-IR/fa-ir";
import store from "../../store/index";

export function thousandCommas(string) {
    return string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function planOrEducationName(plan) {
    if (plan.plan_id == null) {
        return FA_LAN.EDUCATION_NUMBER + " " + plan.educations_id;

    } else {
        const planData = store.getters["plan/find"](plan.plan_id);
        if (planData) {
            return planData.main_category_title + "- " + planData.sub_category_title;
        }
    }
}

export function sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
        if ((new Date().getTime() - start) > milliseconds) {
            break;
        }
    }
}