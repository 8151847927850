<template>
  <div>
    <div class="offcanvas offcanvas-bottom" tabindex="-1" :id="id"
         aria-labelledby="offcanvasBottomLabel">
      <div class="offcanvas-header justify-content-center col-md-6 mx-auto">
        <div class="row all-card-size col-11">
          <h5 class="text-center pt-2">{{ FA_LAN.SUBMIT_REVIEW_ANSWER }}</h5>
          <hr>
          <p class="text-primary">{{ text }}</p>
          <hr>
          <form class="mb-3">
            <div class="form-check form-switch mb-3">
              <label class="form-check-label" for="isTemplate">{{ FA_LAN.RATE_ACCEPTED }}</label>
              <input type="checkbox" class="form-check-input" id="isTemplate" v-model="answer.accepted">
            </div>
            <div class="mb-3" v-if="answer.accepted">
              <label class="form-label">{{ FA_LAN.RATE_ANSWER }}</label>
              <input class="form-control" type="text" v-model.lazy="answer.reply"/>
            </div>
            <div class="mb-3" v-else>
              <div class="form-group">
                <label class="form-label">{{ FA_LAN.REJECT_REASON }}</label>
                <select class="form-control" v-model.lazy="answer.reject_id">
                  <option v-for="rejectReason in rejectReasons" :value="rejectReason.id" :key="rejectReason.id">
                    {{ rejectReason.reason }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-text text-danger">{{ textError }}</div>
            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
              <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" data-bs-dismiss="offcanvas"
                      @click.prevent="submit" :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.SUBMIT_REVIEW_ANSWER }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import persian from "@/fa-IR/fa-ir";
import {computed, reactive, ref} from "vue";
import {useStore} from "vuex";
import {getPost} from "@/js/api/restAPI";
import {sweetAlert} from "@/js/alert/sweetAlert";

export default {
  name: "Bottom",
  props: ['id', 'text', 'reviewId'],
  setup(props) {
    const FA_LAN = persian;
    const formLoader = ref(false);
    const store = useStore();
    const rejectReasons = computed(() => store.getters["reviewRejectReason/get"]);
    const textError = ref('')

    const answer = reactive({
      review_id: props.reviewId,
      accepted: true,
      reply: '',
      reject_id: 0
    })

    const newReview = reactive({
      id: props.reviewId,
      accepted: true,
      reply: '',
      has_reply: true,
      reason: ''
    })

    async function submit() {
      if (answer.accepted && answer.reply.length === 0) {
        textError.value = FA_LAN.ERRORS.EMPTY_ANSWER
        return;

      } else if (!answer.accepted && answer.reject_id === 0) {
        textError.value = FA_LAN.ERRORS.REASON_EMPTY
        return;
      }

      //empty extra data
      if (answer.accepted) {
        answer.reject_id = 0;
      } else {
        answer.reply = '';
      }

      textError.value = '';
      formLoader.value = true;

      await getPost(`admin/reviews/answerReview`, answer)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);

              updateReview();
            }

          }).catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false;
    }

    async function updateReview() {
      if (answer.reject_id != 0) {
        const reason = rejectReasons.value.find(re => re.id == answer.reject_id);
        newReview.reason = reason.reason;
        newReview.has_reply = false;
      }

      if (answer.accepted) {
        newReview.has_reply = true;
        newReview.reason = '';
      }

      newReview.accepted = answer.accepted;
      newReview.reply = answer.reply;

      store.commit('reviews/changeStatus', newReview);
    }

    return {FA_LAN, answer, formLoader, rejectReasons, textError, submit}
  }
}
</script>

<style scoped>
.form-check .form-check-input {
  float: right !important;
  margin-right: 1.5em !important;
  margin-left: 0.5em !important;
}
</style>