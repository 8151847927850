import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const packages = {
    namespaced: true,
    state: {
        packages: [],
    },
    getters: {
        get(state) {
            return state.packages;
        },
        filter: (state) => (code) => {
            return state.packages.find(cat => cat.code === code);
        }
    },
    mutations: {
        set(state, data) {
            state.packages = data;
        }
    }
    ,
    actions: {
        async fetch({commit}) {
            await getPost('getPackages', null)
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        commit("set", response.data.data.packages);
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default packages