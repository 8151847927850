<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="row d-flex justify-content-center">
      <div class="row col-md-5 ms-md-2 mt-3">
        <div class="card shadow-me p-4">
          <div>
            <label class="form-label">{{ FA_LAN.USERNAME }}</label>
            <input class="form-control" type="text" v-model.lazy="newUser.username">
            <label class="form-label mt-2">{{ FA_LAN.PASSWORD }}</label>
            <input class="form-control" type="text" v-model.lazy="newUser.password">
            <div class="d-md-flex justify-content-md-end mt-3">
              <button class="btn btn-sm btn-dark text-center ps-5 pe-5" @click="addUser" :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.ADD_ADMIN }}
              </button>
            </div>
          </div>
        </div>
        <div class="card shadow-me table-responsive p-4 pb-0 mt-4">
          <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
          <table v-else class="table table-striped table-hover">
            <thead class="thead-dark">
            <tr>
              <th scope="col">{{ FA_LAN.USERNAME }}</th>
              <th scope="col">{{ FA_LAN.DATE }}</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.username }}</td>
              <td>{{ tojalali(user.created_at) }}</td>
              <td>
                <button class="btn btn-outline-danger" @click="removeUser(user.id)">
                  <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                  {{ FA_LAN.DELETE }}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-md-5 mt-3">
        <div class="card shadow-me p-4">
          <div>
            <label class="form-label">{{ FA_LAN.PUSH_TITLE }}</label>
            <input class="form-control" type="text" v-model.lazy="push.title">
            <label class="form-label mt-2">{{ FA_LAN.PUSH_MESSAGE }}</label>
            <textarea class="form-control" type="text" v-model.lazy="push.message"></textarea>
            <div class="d-md-flex justify-content-md-end mt-3">
              <button class="btn btn-sm btn-dark text-center ps-5 pe-5" @click="sendPush"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.SEND_PUSH }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {onMounted, reactive, ref} from "vue";
import {getPost} from "../js/api/restAPI";
import {sweetAlert, sweetAlertBTN} from "../js/alert/sweetAlert";
import {gregorian_to_jalali} from "../js/date/convert";

export default {
  name: "Etc",
  setup() {
    const FA_LAN = persian;
    const loading = ref(true);
    const users = ref([]);
    const empty = ref(false);
    const formLoader = ref(false);
    const newUser = reactive({
      username: "",
      password: ""
    })
    const push = reactive({
      title: "",
      message: ""
    })

    onMounted(async () => {
      await getAdminList();
      loading.value = false;
    })

    async function getAdminList() {
      await getPost(`admin/getAdmins`, null)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              users.value = response.data.data.users
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })
      checKEmptyObject();
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = users.value.length === 0;
    }

    function removeUser(userID) {
      const user = users.value.find(user => user.id === userID);
      const title = FA_LAN.sweetAlert.DELETE_ADMIN_TITLE.replace("username", user.username);

      sweetAlertBTN(title).then((result) => {
        if (result.isConfirmed) {
          formLoader.value = true;

          removeUserAPI(userID)
        }
      })
    }

    async function removeUserAPI(userID) {
      await getPost(`admin/deleteAdmin`, {user_id: userID})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              sweetAlert('success', response.data.description)
              getAdminList();
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    async function addUser() {
      if (newUser.username === '' || newUser.password === '') {
        return;
      }

      formLoader.value = true;

      await getPost(`admin/addAdmin`, newUser)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              sweetAlert('success', response.data.description)
              getAdminList();
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    function sendPush() {
      if (push.title === '' || push.message === '') {
        return;
      }

      sweetAlertBTN(FA_LAN.sweetAlert.CONFIRM_SEND_PUSH).then((result) => {
        if (result.isConfirmed) {
          formLoader.value = true;

          sendPushAPI()
        }
      })
    }

    async function sendPushAPI() {
      formLoader.value = true;

      await getPost(`admin/sendPush`, push)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              sweetAlert('success', response.data.description)
              getAdminList();
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      formLoader.value = false;
    }

    return {FA_LAN, loading, users, empty, formLoader, push, newUser, removeUser, addUser, sendPush, tojalali}
  }
}
</script>

<style scoped>

</style>