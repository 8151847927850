import sweetAlert from "./includes/sweetAlert";
import errors from "./includes/errors";
import menu from "./includes/menu";
import forms from "./includes/forms";
import buttons from './includes/buttons';
import tables from "./includes/tables";
import pwa from "./includes/pwa";

const main = {
    MENU: menu,
    ERRORS: errors,
    sweetAlert: sweetAlert,
}

const persian = Object.assign(main, forms, buttons, tables,pwa)

export default persian;