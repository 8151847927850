const tables = {
    NO_DATA: 'نتیجه ای یافت نشد',

    //report
    ALL_USER_COUNT: 'تعداد کل کاربران',
    BUY_USER_COUNT: 'تعداد کل خریداران',
    BUY_MARKET_AMOUNT: 'خرید از مارکت ها',
    BUY_RATE_COUNT: 'نرخ خرید کاربران',
    BUY_RATE_AMOUNT: 'میانگین خرید هر نفر',
    TOTAL_WALLET_GIFTS: 'اعتبار کسب شده',
    TOTAL_WALLET_EXPIRES: 'اعتبار منقضی شده',
    TOTAL_WALLET_USED: 'اعتبار استفاده شده',
    TOTAL_WALLET_PAY_FOR_USED: 'مبلغ پرداخت شده',
    USER_REPEAT_BUY: 'تعداد تکرار خرید',
    USER_COUNT: 'تعداد کاربر',
    BUY_PLAN_COUNT: 'تعداد دوره',
    BUY_KIND: 'نوع',
    BUY_COUNT: 'تعداد',
    BUY_AMOUNT: 'مبلغ',
    //filter
    BEGIN_DATE: 'تاریخ شروع',
    END_DATE: 'تاریخ پایان',

    //main category
    EDUCATION_NAME: 'نام آموزش',
    EDUCATION_COUNT: 'تعداد آموزش',
    UPDATE_DATE: 'تاریخ بروزرسانی',
    CREATED_AT: 'تاریخ ایجاد',
    PUBLISHED_AT: 'تاریخ انتشار',

    //all education video
    DOWNLOAD_FILE: 'دانلود فایل',

    //tickets
    TICKET_TITLE: 'عنوان',
    TICKET_CATEGORY: 'دسته',
    TICKET_NUMBER: 'شماره تیکت',
    TICKET_CREATOR: 'ایجاد کننده',
    TICKET_DESCRIPTION: "توضیحات",
    TICKET_AMOUNT: "هزینه ثبت تیکت",
    TICKET_MIN_AMOUNT: "حداقل هزینه خدمات",
    TICKET_PHONE_OR_NUMBER: "شماره همراه/ شماره تیکت",

    //sms
    CREDIT: "شارژ باقیمانده: ",
    RIAL: " ریال",
    CHARGE_LOG: "تاریخچه شارژ پنل",
    DATE: "تاریخ",
    AMOUNT: "مبلغ (ریال)",
    REFID: "کد پیگیری",
    TEXT: "پیامک",
    OPERATOR: "اپراتور",

    //show plan
    MAIN_AMOUNT: "قیمت اصلی",
    OFF_AMOUNT: "قیمت با تخفیف",
    BAZAAR_CODE: "کد بازار",
    SINGLE_PLANS: "پلن های خرید تک جلسه ای",

    //off
    OFF_TITLE: "عنوان تخفیف",
    VALID_DATE: "تاریخ اعتبار",
    USED: "تعداد استفاده",
    OFF_PLAN_AMOUNT: "مقدار تخفیف",
    PERCENTAGE: " درصد",
    OFF_USER_COUNT: "تعداد ایجاد",

    //reviews
    REVIEW_COUNT: "تعداد نظرات",
    RATE_COUNT: "تعداد امتیازها",
    RATE_AV: "میانگین امتیاز",
    REVIEW: "نظر",
    RATE: "امتیاز",
    RATE_STATUS: "وضعیت",
    RATE_ACCEPTED: "تایید شده",
    RATE_PENDING: "در انتظار",
    RATE_DENY: "رد شده",
    RATE_ANSWER: "پاسخ",
    RATE_DENY_REASON: "دلیل رد",
    RATE_CONFIRM: "تایید نظر",
    RATE_REJECT: "رد نظر",
    NOT_TEXT: "بدون متن...",
    ALL_REVIEW_COUNT: "تعداد کل نظرات ثبت شده: ",
    REVIEW_LOTTERY_COUNT: "تعداد قرعه کشی: ",

    //wallet
    TYPE_TITLE: "نوع",
    TOTAL_COUNT: "تعداد",
    TOTAL_AMOUNT: "اعتبار",
    COUPONS: "کوپن ها",
    WALLET_DESCRIPTION: "توضیحات",
}

export default tables;