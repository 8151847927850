import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const subCategory = {
    namespaced: true,
    state: {
        subCategories: [],
    },
    getters: {
        get(state) {
            return state.subCategories;
        },
        filter(state) {
            return state.subCategories.filter(p => p.code);
        }
    },
    mutations: {
        set(state, data) {
            state.subCategories = data;
        }
    }
    ,
    actions: {
        async fetch({commit}, {mainCategory}) {
            await getPost('admin/category/getSub', {main_category: mainCategory})
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        commit("set", response.data.data.sub_categories);
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default subCategory