import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const reviews = {
    namespaced: true,
    state: {
        reviews: [],
    },
    getters: {
        get(state) {
            return state.reviews;
        }
    },
    mutations: {
        set(state, reviews) {
            state.reviews = reviews;
        },
        add(state, reviews) {
            state.reviews.push(...reviews);
        },
        changeStatus(state, newReview) {
            const index = state.reviews.findIndex(review => review.id == newReview.id);

            state.reviews[index].accepted = newReview.accepted;
            state.reviews[index].reply.text = newReview.reply;
            state.reviews[index].reason = newReview.reason;
            state.reviews[index].has_reply = newReview.has_reply;
        }
    },
    actions: {
        async fetch({commit}, {mainCategory, index}) {

            await getPost(`admin/reviews/getAll?index=${index.value}`, {main_category: mainCategory})
                .then(function (response) {
                    if (response.data.code !== 200) {
                        sweetAlert('error', response.data.description)

                    } else {
                        if (index.value === 0) {
                            commit("set", response.data.data.reviews);
                        } else {
                            commit("add", response.data.data.reviews);
                        }
                    }
                }).catch(function (error) {
                    sweetAlert('error', error)
                })
        }
    }
}


export default reviews