<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else ref='scrollComponent'>
      <div>
        <span v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</span>
        <div v-else>
          <div class="table-responsive card shadow-me p-3 mt-3" v-for="review in reviews" :key="review.id">
            <table class="table table-sm mb-0">
              <tbody>
              <tr class="text-center">
                <td>{{ review.phone }}</td>
                <td class="golden-text">{{ review.rate }} <i class="bi bi-star-fill"></i></td>
                <td>{{ tojalali(review.created_at) }}</td>
                <td :class="getClass(review.accepted)">{{ getStatus(review.accepted) }}</td>
                <td>
                  <p class="m-0">
                    <button class="btn btn-sm btn-outline-dark" data-bs-toggle="offcanvas"
                            :data-bs-target="'#answer'+review.id" aria-controls="offcanvasBottom">
                      <small> {{ FA_LAN.SUBMIT_REVIEW_ANSWER }}</small>
                    </button>
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="6" class="no-border-b"><p class="m-0 pt-1">{{ review.text }}</p>
                  <p class="m-0" v-if="review.has_reply" :class="getClass(review.accepted)">
                    {{ review.reply.text }}
                  </p>
                  <small class="m-0" v-if="review.reason" :class="getClass(review.accepted)">
                    {{ review.reason }}
                  </small>
                </td>
              </tr>
              </tbody>
              <ReviewAnswer
                  :id="'answer'+review.id"
                  :review-id="review.id"
                  :text="review.text"
              />
            </table>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-3 mb-3">
        <div class="spinner-border" role="status" v-if="moreLoader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import {computed, onMounted, onUnmounted, ref} from "vue";
import persian from "@/fa-IR/fa-ir";
import {gregorian_to_jalali} from "@/js/date/convert";
import ReviewAnswer from "@/components/ReviewAnswer";
import {useStore} from "vuex";

export default {
  name: "Reviews",
  components: {ReviewAnswer},
  setup() {
    const route = useRoute();
    const mainCategory = route.params.mainCategory
    const loading = ref(true)
    const moreLoader = ref(false)
    const empty = ref(true);
    const store = useStore();
    const reviews = computed(() => store.getters["reviews/get"]);
    const index = ref(0);
    const FA_LAN = persian;
    const scrollComponent = ref(null);

    async function getReviewList() {
      await store.dispatch('reviews/fetch', {mainCategory, index})
      checKEmptyObject();

      loading.value = false;
      moreLoader.value = false;
    }

    onMounted(() => {
      getReviewList();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (index.value - reviews.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        index.value += 10;
        await getReviewList();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checKEmptyObject() {
      empty.value = reviews.value.length === 0;
    }

    function getStatus(accepted) {
      let status;
      switch (accepted) {
        case true:
          status = FA_LAN.RATE_ACCEPTED;
          break;
        case false:
          status = FA_LAN.RATE_DENY;
          break;
        default:
          status = FA_LAN.RATE_PENDING;
          break;
      }
      return status;
    }

    function getClass(accepted) {
      let className;
      switch (accepted) {
        case true:
          className = 'text-success';
          break;
        case false:
          className = 'text-danger';
          break;
        default:
          className = '';
          break;
      }
      return className;
    }

    function checkPreviousReceive() {
      return reviews.value.length === index.value + 10;
    }


    return {
      empty,
      reviews,
      FA_LAN,
      loading,
      moreLoader,
      scrollComponent,
      loadMore,
      tojalali,
      getStatus,
      getClass,
    }
  }
}
</script>

<style scoped>
.golden-text {
  color: #FFA500;
}

.no-border-b {
  border-bottom: none;
}
</style>