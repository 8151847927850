<template>
  <div v-if="loading" class="modal-me">
    <div class="my-modal-content">
      <div class="spinner-border " role="status"></div>
    </div>
  </div>
  <div v-else>
    <div class="row justify-content-center">
      <div class="col-md-8 p-2 small text-muted d-flex flex-row">
        <div class="col-6">{{ FA_LAN.TICKET_CATEGORY }}: {{ ticket.category_title }}</div>
        <div class="col-6">{{ FA_LAN.TICKET_TITLE }}: {{ ticket.title }}</div>
      </div>
    </div>
    <div class="align-items-center mt-2">
      <TicketRespons v-if="!ticket.close" @formData="sendResponse"
                     @close="close"
                     :text-error="textError"
                     :form-loader="formLoader"
                     :clean-form="cleanForm">
      </TicketRespons>
      <div v-else class="alert alert-warning col-md-8 mx-auto">{{ FA_LAN.ERRORS.TICKET_CLOSED }}</div>
    </div>
    <div v-if="buys.length!==0" class="col-md-8 mx-auto mt-3 card p-2 shadow-me">
      <div class="row">
        <div v-for="buy in buys" :key="buy.id" class="col-md-4 text-muted small">
          {{ getPlanOrEducation(buy) }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-4" ref='scrollComponent'>
      <div class="card col-md-8 p-2 shadow-me">
        <p v-if="empty">{{ FA_LAN.NO_DATA }}</p>
        <div v-else v-for="chat in chatList" :key="chat.id" class="rounded mb-1 col-md-9 p-2 light-back"
             :class="setOwnerStyle(chat.owner)">
          <transition name="fade">
            <div v-if="chat.type=='text'">
              <div class="row">
                <small class="text-black-50 col-6">{{ tojalali(chat.created_at) }}</small>
                <small class="text-black-50  text-start col-6">{{ chat.name }}</small>
              </div>
              <div v-html="chat.text"></div>
            </div>
            <div v-else>
              <small class="text-black-50 ms-3">{{ tojalali(chat.created_at) }}</small>
              <a :href="chat.url" target="_blank" class="btn btn-success btn-sm">{{ FA_LAN.DOWNLOAD_FILE }}</a>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-3">
      <!--      <button @click="loadMore" class="btn btn-outline-secondary btn-sm pe-3 ps-3" :disabled="moreLoader">-->
      <div class="spinner-border" role="status" v-if="moreLoader"></div>
      <!--        {{ FA_LAN.LOAD_MORE }}-->
      <!--      </button>-->
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import {useRoute, useRouter} from "vue-router";
import {gregorian_to_jalali} from "../../js/date/convert";
import TicketRespons from "../../components/TicketRespons";
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";
import {planOrEducationName} from "../../js/functions";

export default {
  name: "Chat",
  components: {TicketRespons},
  setup() {
    const loading = ref(true);
    const formLoader = ref(false);
    const moreLoader = ref(false);
    const empty = ref(true);
    const chatList = ref([]);
    const chatIndex = ref(0);
    const route = useRoute();
    const router = useRouter();
    const textError = ref('');
    const formData = new FormData();
    const fileId = ref('');
    const postChatData = ref({});
    const FA_LAN = persian;
    const cleanForm = ref(false);
    const scrollComponent = ref(null);
    const ticketID = route.params.id;
    const store = useStore();
    const ticket = computed(() => store.getters["ticket/find"](ticketID));
    const buys = ref([]);

    async function getChats() {
      await getPost(`ticket/getChat?index=${chatIndex.value}`, {ticket_id: ticketID})
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              chatList.value.push(...response.data.data.chats);//push for support add more

              chatIndex.value = response.data.index
              checKEmptyObject();
              getUserBuyList();
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })

      loading.value = false;
      moreLoader.value = false;
    }

    onMounted(() => {
      if (!ticket.value) {
        router.push({name: "ticket"})
      }

      getChats();

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    function setOwnerStyle(owner) {
      if (owner === 0) {
        return 'me-auto';
      } else {
        return 'ownerBack';
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    async function sendResponse(form) {
      //check validation
      if (form.text === '' && form.file === '') {
        textError.value = "لطفا اطلاعات را تکمیل نمایید";
        return;
      } else {
        textError.value = "";
      }
      formLoader.value = true;

      //if file exist upload it first
      if (form.file !== '') {
        //upload file
        formData.append("file", form.file[0]);
        await getPost('ticket/uploadFile', formData, true)
            .then(function (response) {
              if (response.data.code !== 200) {
                sweetAlert('error', response.data.description)
              } else {
                fileId.value = response.data.data.file_id;
                sendChat('file', fileId.value); //upload success, send file id in chat

              }
            })
            .catch(function (error) {
              sweetAlert('error', error);
            })
        formLoader.value = false
        //if is text
      } else if (formData.text !== '') {
        sendChat('text', form.text);
      }
    }

    //send text chat or file id chat
    async function sendChat(type, content) {
      if (type === 'text') {
        postChatData.value = {
          ticket_id: ticketID,
          type: type,
          text: content,
          file_id: 0
        }
      } else {
        postChatData.value = {
          ticket_id: ticketID,
          type: type,
          text: '0',
          file_id: content
        }
      }

      await getPost('ticket/newChat', postChatData.value)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)
            } else {
              //refresh all chats
              chatIndex.value = 0
              chatList.value = []; //empty chat list
              getChats(); //refresh chat list if send success

              sweetAlert('success', response.data.description);
              cleanForm.value = true;
            }
          }).catch(function (error) {
            sweetAlert('error', error);
          })
      formLoader.value = false;
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (chatIndex.value - chatList.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        chatIndex.value += 10;
        await getChats();
      }
    }

    async function close() {
      formLoader.value = true;

      await getPost('admin/ticket/closeOpen', {
        "ticket_id": ticketID,
        "close": 1
      })
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description)

            } else {
              sweetAlert('success', response.data.description);
              router.push({name: 'ticket'})
            }
          }).catch(function (error) {
            sweetAlert('error', error)
          })


    }

    function checKEmptyObject() {
      empty.value = chatList.value.length === 0;
    }

    function checkPreviousReceive() {
      return chatList.value.length === chatIndex.value + 10;
    }

    function getUserBuyList() {
      if (chatIndex.value !== 0) {
        return;
      }

      const userChat = chatList.value.find(item => item.name.includes('9'))

      getPost('admin/buy/getUserBuyList', {phone: userChat.name})
          .then(function (response) {
            if (response.data.code == 200) {
              buys.value = response.data.data.buyLists;
            }
          })
    }

    function getPlanOrEducation(plan) {
      return planOrEducationName(plan)
    }

    return {
      chatList,
      loading,
      textError,
      formLoader,
      empty,
      moreLoader,
      FA_LAN,
      cleanForm,
      scrollComponent,
      ticket,
      buys,
      getPlanOrEducation,
      close,
      setOwnerStyle,
      tojalali,
      sendResponse,
      loadMore
    }
  }
}
</script>

<style scoped>
.ownerBack {
  background-color: #cff4fc !important;
  border: none;
}

.light-back {
  background-color: #eceff1;
  border: none;
}

small {
  font-size: 75%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>