<template>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('home')" :to="{name:'home'}">{{ FA_LAN.MENU.HOME }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('reviews')" :to="{name:'reviews'}">{{ FA_LAN.MENU.REVIEWS }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('ticket')" :to="{name:'ticket'}">{{ FA_LAN.MENU.TICKETS }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('buy')" :to="{name:'buy'}">{{ FA_LAN.MENU.BUYS }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('category')" :to="{name:'category'}">{{ FA_LAN.MENU.MAIN_EDUCATIONS }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('package')" :to="{name:'package'}">{{ FA_LAN.MENU.PACKAGES }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('off')" :to="{name:'off'}">{{ FA_LAN.MENU.OFF }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('sms')" :to="{name:'sms'}">{{ FA_LAN.MENU.SMS }}
    </router-link>
  </li>
  <li class="nav-item">
    <router-link class="nav-link"  data-bs-toggle="offcanvas"
                 @click="to('etc')" :to="{name:'etc'}">{{ FA_LAN.MENU.ETC }}
    </router-link>
  </li>
  <li class="nav-item">
    <a class=" nav-link text-danger" @click="logout" href="#">{{ FA_LAN.MENU.LOGOUT }}</a>
  </li>
</template>

<script>
import persian from "../fa-IR/fa-ir";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "MenuItems",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const router = useRouter();

    async function logout() {
      await store.dispatch("apiKey/logout");
    }

    function to(rout) {
      router.push({name: rout})
    }

    return {FA_LAN, logout, to}
  }
}
</script>

<style scoped>
nav {
  font-family: "iransans";
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.nav-link {
  margin-left: 10px;
  border-radius: 5px;
  color: #0000008c;
}

.nav-link:hover {
  background-color: #f5f5f5;
}

.router-link-active {
  color: #39C0ED !important;
  border-bottom: 1px #39C0ED solid;
  border-radius: 0px;
}

</style>