<template>
  <div>
    <div class="d-flex col-md-8">
      <div class="ms-4">
        <router-link class="btn btn-success btn-sm pe-3 ps-3" :to="{name:'newPlans'}">
          {{ FA_LAN.NEW_PLAN }}
        </router-link>
      </div>
    </div>
    <hr>
    <div class="row justify-content-center mb-4" ref='scrollComponent'>
      <div class="card shadow-me table-responsive col-md-10 p-4 pb-0 mt-2">
        <p v-if="empty">{{ FA_LAN.ERRORS.DATA_NOT_EXIST }}</p>
        <table v-else class="table table-striped table-hover">
          <thead class="thead-dark">
          <tr>
            <th scope="col">{{ FA_LAN.PLAN }}</th>
            <th scope="col">{{ FA_LAN.MAIN_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.OFF_AMOUNT }}</th>
            <th scope="col">{{ FA_LAN.BAZAAR_CODE }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="plan in plans" :key="plan.id">
            <td>
              <div>{{ plan.main_category_title }} <small class="text-muted me-1">{{ plan.sub_category_title }}</small>
              </div>
            </td>
            <td>
              <input v-if="isActivePlan(plan.id)" class="form-control" type="number" :id="'bsamount'+plan.id"
                     :value="plan.base_amount"/>
              <span v-else> {{ numberWithCommas(plan.base_amount) }}</span>
            </td>
            <td>
              <input v-if="isActivePlan(plan.id)" class="form-control" type="number" :id="'amount'+plan.id"
                     :value="plan.amount"/>
              <span v-else> {{ numberWithCommas(plan.amount) }}</span>
            </td>
            <td>{{ plan.bazaar_code }}</td>
            <td>
              <button v-if="isActivePlan(plan.id)" class="btn btn-sm btn-dark" @click="saveEdit(plan.id,'plan')"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.SAVE }}
              </button>
              <button v-else class="btn btn-sm btn-success" @click="activeEdit(plan.id,'plan')" :disabled="formLoader">
                {{ FA_LAN.EDIT }}
              </button>
            </td>
          </tr>
          <tr class="table-dark text-center">
            <td colspan="4">{{ FA_LAN.SINGLE_PLANS }}</td>
          </tr>
          <tr v-for="single in singles" :key="single.id">
            <td>
              <div>{{ single.main_category_title }} <small class="text-muted me-1">
                {{ single.sub_category_title }}</small>
              </div>
            </td>
            <td></td>
            <td>
              <input v-if="isActiveSingle(single.id)" class="form-control" type="number" :id="'singleAmount'+single.id"
                     :value="single.amount"/>
              <span v-else> {{ numberWithCommas(single.amount) }}</span>
            </td>
            <td>{{ single.bazaar_code }}</td>
            <td>
              <button v-if="isActiveSingle(single.id)" class="btn btn-sm btn-dark" @click="saveEdit(single.id,'single')"
                      :disabled="formLoader">
                <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
                {{ FA_LAN.SAVE }}
              </button>
              <button v-else class="btn btn-sm btn-success" @click="activeEdit(single.id,'single')"
                      :disabled="formLoader">
                {{ FA_LAN.EDIT }}
              </button>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center mt-3 mb-3" v-if="loader">
          <div class="spinner-border" role="status"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import persian from "../../fa-IR/fa-ir";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watch} from "vue";
import {thousandCommas} from "../../js/functions";
import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

export default {
  name: "List",
  setup() {
    const FA_LAN = persian;
    const store = useStore();
    const plans = computed(() => store.getters["plan/get"]);
    const empty = ref(true);
    const singles = computed(() => store.getters["plan/getSingle"]);
    const loader = ref(true);
    const planActiveID = ref(0);
    const singleActiveID = ref(0);
    const successEdit = ref(false);
    const formLoader = ref(false);
    const plan = reactive({
      plan_id: '',
      main_category: '',
      sub_category: '',
      amount: '',
      base_amount: '',
      bazaar_code: '',
    })

    onMounted(async () => {
      checKEmptyObject();

      await store.dispatch('plan/fetchSingle');
      loader.value = false;
    })

    watch(plans, () => {
      checKEmptyObject();
    })

    function numberWithCommas(value) {
      return value ? thousandCommas(value) : value;
    }

    function checKEmptyObject() {
      empty.value = plans.value.length === 0;
    }

    function isActivePlan(id) {
      return id == planActiveID.value
    }

    function isActiveSingle(id) {
      return id == singleActiveID.value
    }

    function activeEdit(id, type) {
      if (type === 'plan') {
        planActiveID.value = id;
        singleActiveID.value = 0;

      } else {
        singleActiveID.value = id
        planActiveID.value = 0
      }
    }

    async function saveEdit(id, type) {
      formLoader.value = true

      let url;
      if (type === "plan") {
        url = 'admin/plans/edit';
      } else {
        url = 'admin/plans/single/edit';
      }

      setPlan(id, type);

      await getPost(url, plan)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
            } else {
              sweetAlert('success', response.data.description);
              successEdit.value = true;
            }
          }).catch(function (error) {
            sweetAlert('error', error);
          })

      if (successEdit.value) {
        if (type === 'plan') {
          await store.dispatch('plan/fetch');
          planActiveID.value = 0;

        } else {
          await store.dispatch('plan/fetchSingle');
          singleActiveID.value = 0;
        }

        successEdit.value = false;
      }

      formLoader.value = false
    }

    function setPlan(id, type) {
      let basePlan;
      if (type === 'plan') {
        basePlan = plans.value.find(item => item.id == id);
      } else {
        basePlan = singles.value.find(item => item.id == id);
      }

      plan.plan_id = id;
      plan.main_category = basePlan.main_category
      plan.sub_category = basePlan.sub_category
      plan.bazaar_code = basePlan.bazaar_code

      if (type === 'plan') {
        plan.amount = document.getElementById('amount' + id).value;
        plan.base_amount = document.getElementById('bsamount' + id).value;

      } else {
        plan.amount = document.getElementById('singleAmount' + id).value;
      }
    }

    return {
      FA_LAN,
      plans,
      empty,
      singles,
      loader,
      formLoader,
      isActivePlan,
      isActiveSingle,
      activeEdit,
      saveEdit,
      numberWithCommas
    }
  }
}
</script>

<style scoped>

</style>