<template>
  <div v-if="videoNumber">
    <h3>{{ videoNumber }}- {{ videoTitle }} ({{ videoCategory }})</h3>
    <div class="row col-md-8">
      <h4 class="text-danger mt-4 col-md-8">{{ FA_LAN.ERRORS.DELETE_EDUCATION_CONFIRM }}</h4>
      <div class="col-md-4 d-flex justify-content-sm-start align-items-center ">
        <button class="btn btn-danger btn-sm ps-5 pe-5" @click="confirmAccept" :disabled="confirm===0">
          <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
          {{ FA_LAN.DELETE }} ({{ confirm }})
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import persian from "../../../fa-IR/fa-ir";

export default {
  name: "Delete",
  setup() {
    const route = useRoute();
    // const router = useRouter();
    const videoId = ref(route.params.id);
    const videoNumber = ref(route.params.educationNumber);
    const videoTitle = ref(route.params.educationTitle);
    const videoCategory = ref(route.params.mainCategory);
    const router = useRouter();
    const loading = ref(false);
    const confirm = ref(3);
    const FA_LAN = persian;


    async function deleteEducation() {
      loading.value = true;

      await getPost(`admin/education/package/delete`, {
        package: videoCategory.value,
        education_id: videoId.value
      })
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);
              confirm.value = 3;
            } else {
              sweetAlert('success', response.data.description);

              router.push({name: 'getPackageEducationVideo', params: {package: videoCategory.value}})
            }

          }).catch(function (error) {
            sweetAlert('error', error);
            confirm.value = 3;
          });
      loading.value = false;
    }

    watch(confirm, () => {
      if (confirm.value === 0) {
        deleteEducation();
      }
    })

    function confirmAccept() {
      confirm.value -= 1;
    }

    return {videoId, loading, FA_LAN, videoNumber, videoTitle, confirm, videoCategory, confirmAccept}
  }
}
</script>

<style scoped>

</style>