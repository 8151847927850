<template>
  <div>
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="card shadow-me justify-content-center mt-lg-5 p-3" ref='scrollComponent'>
      <div class="mb-3 mb-3 col-md-4">
        <label class="form-label">{{ FA_LAN.SEARCH }}</label>
        <input type="text" class="form-control" v-model.lazy="searchItem">
      </div>
      <hr>
      <div v-if="empty" class="mb-3">
        <p>{{ FA_LAN.NO_DATA }}</p>
      </div>
      <div v-else class="table-responsive">
        <table class="table table-hover table-striped ">
          <thead>
          <tr>
            <th scope="col">{{ FA_LAN.SUB_CATEGORY }}</th>
            <th scope="col">{{ FA_LAN.EDUCATION_NUMBER }}</th>
            <th scope="col">{{ FA_LAN.EDUCATION_TITLE }}</th>
            <th scope="col">{{ FA_LAN.TAGS }}</th>
            <th scope="col">{{ FA_LAN.EXTRA_FILE }}</th>
            <th scope="col">{{ FA_LAN.MAIN_EDUCATION_LINK }}</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="video in videos" :key="video.id">
            <td>{{ video.main_category }}</td>
            <td>{{ video.education_number }}</td>
            <td>{{ video.title }}</td>
            <td>{{ video.tags }}</td>
            <td>
              <a class="link text-black" :href="video.extra_url"
                 v-if="checkExistUrl(video.extra_url)">{{ FA_LAN.EXTRA_FILE }}</a>
            </td>
            <td><a class="link text-black" :href="video.url">{{ FA_LAN.MAIN_EDUCATION_LINK }}</a></td>
            <td>
              <router-link class="btn btn-outline-danger"
                           :to="{name:'deletePackageEducation',params:{
                             id:video.id,
                             educationNumber:video.education_number,
                             educationTitle:video.title,
                             mainCategory:packages,
                           }}">
                {{ FA_LAN.DELETE }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-3 mb-3">
      <!--      <button @click="loadMore" class="btn btn-outline-secondary btn-sm pe-3 ps-3" :disabled="moreLoader">-->
      <div class="spinner-border" role="status" v-if="moreLoader"></div>
      <!--        {{ FA_LAN.LOAD_MORE }}-->
      <!--      </button>-->
    </div>
  </div>
</template>

<script>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import persian from "../../../fa-IR/fa-ir";
import {useRoute} from "vue-router";
import {gregorian_to_jalali} from "../../../js/date/convert";
import {useStore} from "vuex";

export default {
  name: "EducationList",
  setup() {
    const loading = ref(true);
    const moreLoader = ref(false);
    const empty = ref(true);
    const videoIndex = ref(0);
    const searchItem = ref('');
    const FA_LAN = persian;
    const route = useRoute();
    const scrollComponent = ref(null);
    const store = useStore();
    const videos = computed(() => store.getters["education/get"]);
    const packages = route.params.package;

    watch(videos, () => {
      checKEmptyObject();
    })

    watch(searchItem, () => {
      moreLoader.value = true;
      videoIndex.value = 0;
      getVideos();
    })

    async function getVideos() {
      await store.dispatch('education/fetchPackage', {
        index: videoIndex.value,
        packages: packages,
        search: searchItem.value,
      })

      loading.value = false;
      moreLoader.value = false;
    }

    onMounted(async () => {
      await getVideos();
      await loadMore();//for large screen

      window.addEventListener('scroll', loadMore);
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', loadMore);
    })

    function checKEmptyObject() {
      empty.value = videos.value.length === 0;
    }

    async function loadMore() {
      if (!checkPreviousReceive()) {
        return
      }

      let element = scrollComponent.value
      if (element.getBoundingClientRect().bottom < window.innerHeight) {

        if (videoIndex.value - videos.value.length > 10) { //for stop looping
          return;
        }
        moreLoader.value = true;
        videoIndex.value += 10;
        await getVideos();
      }
    }

    function tojalali(miladi) {
      return gregorian_to_jalali(miladi);
    }

    function checkExistUrl(url) {
      return url !== null;
    }

    function checkPreviousReceive() {
      return videos.value.length === videoIndex.value + 10;
    }


    return {
      loading,
      FA_LAN,
      empty,
      searchItem,
      moreLoader,
      videos,
      packages,
      scrollComponent,
      loadMore,
      tojalali,
      checkExistUrl
    }
  }
}
</script>

<style scoped>

</style>