<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <div v-if="loading" class="modal-me">
      <div class="my-modal-content">
        <div class="spinner-border " role="status"></div>
      </div>
    </div>
    <div v-else class="row col-md-8 ">
      <form class="col-12 card shadow-me p-3">
        <div class="form-check form-switch mb-3">
          <label class="form-check-label" for="forOther">{{ FA_LAN.UNIQUE }}</label>
          <input type="checkbox" class="form-check-input" id="forOther" checked v-model="off.unique">
        </div>
        <div class="mb-3">
          <label class="form-label">{{ FA_LAN.PHONE }}</label>
          <input class="form-control" type="number" v-model.lazy="off.phone"/>
        </div>
        <div class="mb-3">
          <div class="form-group">
            <label>{{ FA_LAN.OFF_TITLE }}</label>
            <select class="form-control" v-model.lazy="off.off_id">
              <option v-for="offTemp in offTemps" :key="offTemp.id" :value="offTemp.id">
                {{ offTemp.title }} - {{ offTemp.amount }}{{ offType(offTemp.percentage) }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
            <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
            {{ FA_LAN.SUBMIT_USER_OFF }}
          </button>
        </div>
      </form>
      <div class="col-12">
        <div v-for="offTemp in offTemps" :key="offTemp.id" class="row bg-white shadow-me mt-3 p-2">
          <div class="col-md-5 col-11">
            <img :src="offTemp.image">
          </div>
          <div class="col-md-6 col-11">
            <p class="text-info m-0 mb-1 mt-1">{{ offTemp.title }}</p>
            <small>{{ offTemp.description }}</small>
            <p class="m-0 mt-3">{{ FA_LAN.OFF_PLAN_AMOUNT }}: {{ offTemp.amount }}{{ offType(offTemp.percentage) }}</p>
            <p class="m-0 mt-1">{{ FA_LAN.OFF_LIMIT_COUNT }}: {{ offTemp.limit_count }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {getPost,} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";
import persian from "../../fa-IR/fa-ir";
import {useRoute} from "vue-router";

export default {
  name: "New",
  setup() {
    const off = reactive({
      phone: '',
      unique: true,
      off_id: '',
    })
    const FA_LAN = persian;
    const offTemps = ref([])
    const formLoader = ref(false);
    const loading = ref(true);
    const route = useRoute();

    onMounted(() => {
      getOffPlans();

      if (route.params.tempID) {
        off.off_id = route.params.tempID;
      }
    })

    async function submit() {
      formLoader.value = true;

      await getPost('admin/off/activeForUser', off)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success', response.data.description);
            }

          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    async function getOffPlans() {
      await getPost('admin/off/getTemplates', off)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              offTemps.value = response.data.data.offs;
            }

          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      loading.value = false

    }

    function offType(isPercentage) {
      return isPercentage ? FA_LAN.PERCENTAGE : FA_LAN.TOMAN;
    }

    return {off, offTemps, loading, formLoader, FA_LAN, submit, offType};
  }

}
</script>

<style scoped>
.form-check .form-check-input {
  float: right !important;
  margin-right: 1.5em !important;
  margin-left: 0.5em !important;
}

img {
  max-width: 100%;
}
</style>