import {getPost} from "../../js/api/restAPI";
import {sweetAlert} from "../../js/alert/sweetAlert";

const education = {
    namespaced: true,
    state: {
        educations: [],
    },
    getters: {
        get(state) {
            return state.educations;
        }
    },
    mutations: {
        setNew(state, data) {
            state.educations = data;
        },
        add(state, data) {
            state.educations.push(...data);
        }
    },
    actions: {
        async fetch({commit}, {mainCategory, search, index}) {
            await getPost(`admin/education/main/getList?index=${index}`, {
                main_category: mainCategory,
                search: search,
            }).then(function (response) {
                if (response.data.code !== 200) {
                    sweetAlert('error', response.data.description)

                } else {
                    if (index === 0) {//new search or fetch
                        commit("setNew", response.data.data.educations);
                    } else {
                        commit("add", response.data.data.educations);
                    }
                }
            }).catch(function (error) {
                sweetAlert('error', error)
            })
        },

        async fetchPackage({commit}, {packages, search, index}) {
            await getPost(`admin/education/package/getList?index=${index}`, {
                main_category: packages,
                search: search,
            }).then(function (response) {
                if (response.data.code !== 200) {
                    sweetAlert('error', response.data.description)

                } else {
                    if (index === 0) {//new search or fetch
                        commit("setNew", response.data.data.package_educations);
                    } else {
                        commit("add", response.data.data.package_educations);
                    }
                }
            }).catch(function (error) {
                sweetAlert('error', error)
            })
        }
    }
}


export default education