<template>
  <div class="d-flex justify-content-center mt-lg-5 mb-5">
    <form class="col-md-8  card shadow-me p-3">
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.MAIN_CATEGORY }}</label>
        <input class="form-control" type="text" disabled :value="mainCategoryTitle"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.CATEGORY_CODE }}</label>
        <input class="form-control" type="text" v-model.lazy="subCategory.code"/>
      </div>
      <div class="mb-3">
        <label class="form-label">{{ FA_LAN.SUB_CATEGORY }}</label>
        <input class="form-control" type="text" v-model.lazy="subCategory.title"/>
      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button type="submit" class="btn btn-dark btn-sm ps-5 pe-5" @click.prevent="submit" :disabled="formLoader">
          <div class="spinner-border spinner-border-sm" role="status" v-if="formLoader"></div>
          {{ FA_LAN.CATEGORY_SUBMIT }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {computed, onMounted, reactive, ref, watch} from "vue";
import persian from "../../../fa-IR/fa-ir";
import {getPost} from "../../../js/api/restAPI";
import {sweetAlert} from "../../../js/alert/sweetAlert";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

export default {
  name: "New",
  setup() {
    const route = useRoute();
    const mainCategory = route.params.mainCategory;
    const subCategory = reactive({
      main_category: mainCategory,
      code: '',
      title: ''
    })
    const FA_LAN = persian;
    const formLoader = ref(false);
    const store = useStore();
    const mainCategoryDetail = computed(() => store.getters["mainCategory/filter"](mainCategory));
    const mainCategoryTitle = ref('');

    onMounted(() => {
      setCategoryName()
    })

    watch(mainCategoryDetail, () => {
      setCategoryName()
    })

    function setCategoryName() {
      mainCategoryTitle.value = mainCategoryDetail.value.title
    }

    async function submit() {
      formLoader.value = true;

      await getPost('admin/category/createSub', subCategory)
          .then(function (response) {
            if (response.data.code !== 200) {
              sweetAlert('error', response.data.description);

            } else {
              sweetAlert('success', response.data.description);
              store.dispatch("subCategory/fetch", {mainCategory: mainCategory})
            }
          })
          .catch(function (error) {
            sweetAlert('error', error);
          })

      formLoader.value = false
    }

    return {FA_LAN, subCategory, formLoader, mainCategoryTitle, submit}
  }
}
</script>

<style scoped>

</style>